/* 
TODO
- LegalMonetaryTotal.LineExtensionAmount
  Is this total, total without tax?

- ClassifiedTaxCategory.ID
  For non vat excluded companies, what values should this hold?
*/

export class InvoiceLine {
  constructor(lineId) {
    this.lineId = lineId
    this.description = ''
    this.quantity = 1
    this.unit = ''
    this.price = 1
    this.tax = 0
    this.details = null
    this.sum = 1
  }
}

export class Invoice {
  constructor(
    identity, // INV0003
    issueDate, // 2024-01-29
    dueDate, // 2024-02-25
    termsAndConditions,

    nameSender, // MOLNAR SOLUTIONS S.R.L.
    emailSender, // a@b.c
    cuiSender, // 12345678
    addressSender, // TURDA str. CONSTRUCTORILOR nr. 8
    citySender, // TURDA
    countySender, // CJ !for romania, the actual value of CountrySubentity is RO-CJ
    ibanSender, // TODO: add iban field RO84BTRLRONCRT0CM7378101

    nameRecipient,
    emailRecipient,
    cuiRecipient,
    addressRecipient,
    cityRecipient,
    countyRecipient,

    lines,

    //TODO
    taxCode = 0,
    taxExemptionReasonCode = 'VATEX-EU-O',
    taxExemptionReason = 'Entitatea nu este inregistrata in scopuri de TVA',
    paid = true
  ) {
    this.identity = identity
    this.issueDate = issueDate
    this.dueDate = dueDate
    this.termsAndConditions = termsAndConditions
    this.nameSender = nameSender
    this.emailSender = emailSender
    this.cuiSender = cuiSender
    this.addressSender = addressSender
    this.citySender = citySender
    this.countySender = countySender
    this.ibanSender = ibanSender
    this.nameRecipient = nameRecipient
    this.emailRecipient = emailRecipient
    this.cuiRecipient = cuiRecipient
    this.addressRecipient = addressRecipient
    this.cityRecipient = cityRecipient
    this.countyRecipient = countyRecipient
    this.lines = lines

    this.taxCode = taxCode
    this.taxExemptionReasonCode = taxExemptionReasonCode
    this.taxExemptionReason = taxExemptionReason
    this.paid = paid
  }
}

export class Customer {
  constructor(name, email, cui, address, city, county, invoiceTerms) {
    this.name = name
    this.email = email
    this.cui = cui
    this.address = address
    this.city = city
    this.county = county
    this.invoiceTerms = invoiceTerms
  }
}
