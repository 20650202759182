import React from 'react'
import { useState, useEffect } from 'react'
import globals from '../globals'
import { useNavigate } from 'react-router-dom'
import LoadingOverlay from '../components/LoadingOverlay'
import { useTranslation } from 'react-i18next'

export default function LogIn({
  showLogIn,
  setShowLogIn,
  setIsSubmittedLogIn,

  setUserEmail,
  setUserRole,
}) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [reset, setReset] = useState(false)
  const [resetDone, setResetDone] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setResetDone(false)
    setError('')
  }, [reset])

  async function submitLogin(event) {
    console.debug('Requesting login.')
    event.preventDefault()

    window.localStorage.removeItem('token')
    setUserEmail('')
    setUserRole('')
    setIsSubmittedLogIn(false)
    setError('')
    setLoading(true)

    try {
      const formData = new FormData(event.target)
      const res = await fetch(`${globals.getEndpoint()}/login`, { method: 'POST', body: formData })
      if (res.ok) {
        const tokenAndRole = await res.text()
        setIsSubmittedLogIn(true)
        const items = tokenAndRole.split(';')
        const token = items[0]
        const role = items[1]

        window.localStorage.setItem('token', token)
        setUserEmail(formData.get('username'))
        setUserRole(role)
        setShowLogIn(false)
        console.debug(`${formData.get('username')} logged in with role ${role} and token ${token}`)
      } else {
        if (res.status === 401) setError(t('form_credentials_invalid'))
        else if (res.status === 402) navigate('/paymentDue')
        else {
          throw Error('An unknown error occured.')
        }
      }
    } catch (e) {
      setError(`${e}`)
    } finally {
      setLoading(false)
    }
  }

  async function submitReset(event) {
    console.debug('Requesting pass reset.')
    event.preventDefault()
    setLoading(true)

    try {
      const res = await fetch(`${globals.getEndpoint()}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(new FormData(event.target)),
      })
      if (res.ok) {
        setResetDone(true)
      } else {
        throw Error('Incorrect credentials.')
      }
    } catch (e) {
      setError(`${e}`)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = (
    <>
      <form onSubmit={submitReset} name='reset'>
        <h5>Reset Password</h5>
        <div className='field label prefix border'>
          <i>mail</i>
          <input type='email' name='userEmail' required id='reset_username'/>
          <label forhtml='userEmail'>User Email</label>
        </div>

        {resetDone && (
          <>
            <p className='primary-text'>Recovery email sent.</p>
            <p className='small'>Don't forget to check your spam folder.</p>
          </>
        )}

        <ul>
          <li>You will be sent a recovery link to your email.</li>
          <li>This email can take up to a minute to arrive.</li>
          <li>Check your spam folder too if you do not receive it.</li>
          <li>The email contains a link you will have to visit.</li>
        </ul>

        <button className='transparent link' type='button' onClick={() => setReset(false)} id='reset_go_login'>
          <i>person</i>Log in
        </button>
        <div className='small-divider' />
        <nav>
          <button className='secondary' type='button' onClick={() => setShowLogIn(false)} id='reset_cancel'>
            <i>close</i>Cancel
          </button>
          <div className='max' />
          <button className='primary' type='submit' id='reset_submit'>
            Request reset<i>send</i>
          </button>
        </nav>
      </form>
    </>
  )

  const loginForm = (
    <>
      <form onSubmit={submitLogin} name='login'>
        <h5>Log in</h5>
        <div className='field label prefix border'>
          <i>person</i>
          <input type='email' name='username' required id='login_username'/>
          <label forhtml='username'>User Email</label>
        </div>

        <div className='field label prefix border'>
          <i>password</i>
          <input type='password' name='password' required id='login_password'/>
          <label forhtml='password'>Password</label>
        </div>

        {error && <p className='error-text'>{error}</p>}

        <button
          className='transparent link'
          type='button'
          onClick={() => {
            setReset(true)
          }}
          id='login_go_reset'>
          <i>key</i>
          Forgot your password?
        </button>

        <div className='small-divider' />
        <nav className='right-align'>
          <button className='secondary' type='button' onClick={() => setShowLogIn(false)} id='login_cancel'>
            <i>close</i> Cancel
          </button>
          <div className='max' />
          <button className='primary' type='submit' id='login_submit'>
            Log in <i>send</i>
          </button>
        </nav>
      </form>
    </>
  )

  return (
    <>
      <div className={`overlay ${showLogIn ? 'active' : ''}`} onClick={() => setShowLogIn(false)} />
      <dialog className={showLogIn ? 'active' : ''} id='login_dialog'>
        <button
          className='circle transparent absolute top right margin'
          onClick={() => setShowLogIn(false)}
          id='login_dialog_close'>
          <i>close</i>
        </button>
        {reset ? resetForm : loginForm}

        {loading && <LoadingOverlay />}
      </dialog>
    </>
  )
}
