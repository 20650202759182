import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'
import LoadingOverlay from '../components/LoadingOverlay'
import accounting_actions from '../assets/accounting_actions.png'

export default function ContactPage() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  async function submitContact(event) {
    console.debug('Log the request so we know in prod, our listener is being called.')
    event.preventDefault()
    setLoading(true)
    try {
      const res = await fetch(`${globals.getEndpoint()}/support`, {
        method: 'POST',
        body: new FormData(event.target),
      })
      if (res.ok)
        setSuccess(true)
      else
        setError(await res.text())
    } catch (err) {
      setError('Service unavailable')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <main className='responsive'>
        <img
          className='absolute bottom left l'
          src={accounting_actions}
          width='60%'
          alt=''
          style={{ opacity: 0.8, marginLeft: '6rem' }}
        />

        <div className='large-margin grid'>
          <div className='s12 m12 l6'>
            <div className='large-padding'>
              <h1 className='primary-text'>{t('contact_title')}</h1>
              <p>{t('contact_body')}</p>
            </div>
          </div>
          <article className='s12 m12 l6 round'>
            <h2>{t('contact_form_title')}</h2>
            {!success && (
              <form onSubmit={submitContact}>
                <div className='field label prefix border'>
                  <i>mail</i>
                  <input type='email' name='email' required id='contact_email'/>
                  <label forhtml='email'>{t('contact_form_email')}</label>
                  <span className='helper'>{t('contact_form_email_hint')}</span>
                </div>

                <div className='field label prefix border'>
                  <i>question_mark</i>
                  <input type='text' name='subject' required id='contact_subject'/>
                  <label forhtml='subject'>{t('contact_form_topic')}</label>
                  <span className='helper'>{t('contact_form_topic_hint')}</span>
                </div>

                <div className='field textarea label border extra'>
                  <textarea name='text' required id='contact_text'></textarea>
                  <label forhtml='text'>{t('contact_form_message')}</label>
                </div>

                <nav>
                  {error !== '' && <p className='error-text'>{error}</p>}
                  {success && <p className='primary-text'>{t('contact_form_done')}</p>}
                  <div className='max' />
                  <button className='extra elevate' type='submit'>
                    <span>{t('contact_form_button')}</span>
                    <i>phone</i>
                  </button>
                </nav>
              </form>
            )}

            {loading && <LoadingOverlay />}
          </article>
        </div>
      </main>
    </>
  )
}
