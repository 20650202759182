
const getEndpoint = function() {
    return process.env.REACT_APP_API_ENDPOINT
}

const getPaymentLinks = function() {
    return {
        both: process.env.REACT_APP_STRIPE_API_PRODUCT_BOTH,
        user: process.env.REACT_APP_STRIPE_API_PRODUCT_REGULAR_SEAT,
        mngr: process.env.REACT_APP_STRIPE_API_PRODUCT_MANAGER_SEAT,
        portal: process.env.REACT_APP_STRIPE_API_CUSTOMER_PORTAL,
    }
}

const globals = {
    getEndpoint: getEndpoint,
    getPaymentLinks: getPaymentLinks
}
export default globals
