import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

export default function NavBar({ isSubmittedLogIn, setIsSubmittedLogIn, isUserPro, setShowLogIn, userRole, isMaintenance }) {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [mobileOpenDrawer, setMobileOpenDrawer] = useState(false)

  const invoicing = (
    <a className={navbarClassList(true, '/invoice')} onClick={e => nav(e, '/invoice')} href='/invoice'>
      <i>receipt_long</i>
      {t('navbar_invoice')}
    </a>
  )

  function handleLogOut(e) {
    e.preventDefault()
    localStorage.removeItem('token')
    setIsSubmittedLogIn(false)
    navigate('/')
    return false
  }

  function toggleLocale(e) {
    e.preventDefault()
    i18n.changeLanguage(i18n.language === 'ro' ? 'en' : 'ro')
    return false
  }

  function toggleDarkMode(e) {
    e.preventDefault()
    window.localStorage.setItem('darkmode', document.body.classList.toggle('dark'))
    return false
  }

  function closeDrawer(e) {
    e.preventDefault()
    setMobileOpenDrawer(false)
    return false
  }

  function nav(e, href) {
    e.preventDefault()
    setMobileOpenDrawer(false)
    navigate(href)
    return false
  }

  function navbarClassList(vertical, path) {
    return `${vertical ? 'vertical' : ''} ${location.pathname === path ? 'active' : ''}`
  }

  function links(vertical = false) {
    return (
      <>
        {isSubmittedLogIn && (
          <button className='circle' id='navbar_account'>
            <i>person</i>
            <menu className='no-wrap max'>
              <a onClick={e => nav(e, '/account')} href='/account' id='navbar_account_settings'>
                <i>settings</i>
                {t('navbar_settings')}
              </a>
              <a onClick={handleLogOut} href='/' id='navbar_account_logout'>
                <i>logout</i>
                {t('navbar_logout')}
              </a>
            </menu>
          </button>
        )}

        <a className={navbarClassList(vertical, '/')} onClick={e => nav(e, '/')} href='/' id='navbar_home'>
          <i>home</i>
          {t('navbar_home')}
        </a>

        {!isSubmittedLogIn && !isMaintenance && (
          <>
            <div className='divider' />
            <a
              className={vertical ? 'vertical' : ''}
              onClick={e => nav(e, '/signup')}
              href='/signup'
              id='navbar_signup'>
              <i>person_add</i>
              {t('navbar_signup')}
            </a>
            <a
              className={`${vertical ? 'vertical' : ''}`}
              onClick={e => {
                e.preventDefault()
                setMobileOpenDrawer(false)
                setShowLogIn(true)
              }}
              href='/'
              id='navbar_login'>
              <i>login</i>
              {t('navbar_login')}
            </a>
          </>
        )}

        {isMaintenance && (
          <a className={navbarClassList(vertical, '') + ' error-text'}>
            <i>warning</i>
            {t('navbar_maint')}
          </a>
        )}

        <div className='divider' />

        {isSubmittedLogIn && (
          <>
            <a
              className={navbarClassList(vertical, '/manageBooks')}
              onClick={e => nav(e, '/manageBooks')}
              href='/manageBooks'
              id='navbar_books'>
              <i>edit_note</i>
              {t('navbar_books')}
            </a>
            <a
              className={navbarClassList(vertical, '/manageTaxes')}
              onClick={e => nav(e, '/manageTaxes')}
              href='/manageTaxes'
              id='navbar_taxes'>
              <i>home_storage</i>
              {t('navbar_taxes')}
            </a>
          </>
        )}

        {isUserPro && (
          <a className={navbarClassList(vertical, '/bulk')} onClick={e => nav(e, '/bulk')} id='navbar_bulk'>
            <i>table_view</i>
            {t('navbar_bulk')}
          </a>
        )}

        {userRole === 'admin' && (
          <a
            className={navbarClassList(vertical, '/admin')}
            onClick={e => nav(e, '/admin')}
            href='/admin'
            id='navbar_admin'>
            <i>construction</i>
            Admin
          </a>
        )}

        <a
          className={navbarClassList(vertical, '/contact')}
          onClick={e => nav(e, '/contact')}
          href='/contact'
          id='navbar_contact'>
          <i>phone</i>
          {t('navbar_contact')}
        </a>

        <div className='divider' />

        <a className='small transparent circle button' onClick={toggleLocale} href='#' id='navbar_locale'>
          {i18n.language === 'ro' ? '🇺🇸' : '🇷🇴'}
        </a>

        <a className='small transparent circle button' onClick={toggleDarkMode} href='#' id='navbar_darkmode'>
          <i>light_mode</i>
        </a>
      </>
    )
  }

  return (
    <>
      <nav className='l m left vertical scroll center-align' id='navbar_esktop'>
        {links(true)}
      </nav>

      <nav className='s bottom large-elevate' id='navbar_mobile'>
        <button className='circle' onClick={() => setMobileOpenDrawer(true)} id='navbar_mobile_open'>
          <i>menu</i>
        </button>
      </nav>

      <dialog className={`max ${mobileOpenDrawer ? 'active' : ''}`}>
        <nav className='drawer max'>
          {links(false)}
          <a className='fixed bottom right large-margin' onClick={closeDrawer} href='#' id='navbar_mobile_close'>
            <i>close</i>
          </a>
        </nav>
      </dialog>
    </>
  )
}
