import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'
import robotg from '../assets/robot_green.png'
import calculator from '../assets/calculator.png'
import LoadingOverlay from '../components/LoadingOverlay'

export default function ManageTaxesPage() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [d100, setD100] = useState(null)
  const [d112Is, setD112Is] = useState(null)
  const [d112Cas, setD112Cas] = useState(null)
  const [d112Cass, setD112Cass] = useState(null)
  const [d112Cam, setD112Cam] = useState(null)
  const [d112Secondaries, setD112Secondaries] = useState({})
  const [tva, setTva] = useState(null)

  async function updateMetricSentEmailToClient() {
    try {
      const res = await fetch(`${globals.getEndpoint()}/metrics/email`, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        method:'POST',
        body: `user=${localStorage.getItem('token')}`
      })
      if (res.status === 401) {
        window.location.reload()
        return
      }
      if (res.status !== 200) {
        console.warn(`Error updating metric email sent: ${res.status}`)
      }
    } catch (error) {
      console.warn('Error updating metric email sent.', error);
    }
  }

  function falsyToZero(v){return v ? Number(v) : 0}

  async function getCifNameAddress() {
    const result = ['', '', '']
    try {
      const res = await fetch(`${globals.getEndpoint()}/taxes?user=${localStorage.getItem('token')}`)
      if (res.status === 401) {
        window.location.reload()
        return
      }
      if (res.status === 200) {
        const json = await res.json()
        result[0] = json['cif'].trim()
        result[1] = json['name'].trim()
        result[2] = json['addr'].trim()
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  async function handleSendEmail() {
    updateMetricSentEmailToClient()
    const [cif, name, addr] = await getCifNameAddress()
    let D112SecondaryString = ""
    for (const [key, value] of Object.entries(d112Secondaries))
      D112SecondaryString += `D112-IS (sediu secundar) cif ${key}: ${falsyToZero(value)} RON\n`
    const bodyContent = `Taxes Data:

D100: ${falsyToZero(d100)} RON

D112-IS: ${falsyToZero(d112Is)} RON
D112-CAS: ${falsyToZero(d112Cas)} RON
D112-CASS: ${falsyToZero(d112Cass)} RON
D112-CAM: ${falsyToZero(d112Cam)} RON
${D112SecondaryString}
TVA: ${falsyToZero(tva)} RON

TOTAL: ${falsyToZero(d100)+falsyToZero(d112Is)+falsyToZero(d112Cas)+falsyToZero(d112Cam)+falsyToZero(tva)} RON
${name?name:''}
CIF: ${cif?cif:''}
${addr?addr:''}
`
    console.log(bodyContent)
    const mailtoLink = `mailto:me@domain.com?subject=Taxes&body=${encodeURIComponent(bodyContent)}`
    window.location.href = mailtoLink
  }

  async function handleUpload(event) {
    setLoading(true)
    setError('')
    const selectedFile = event.target.files[0]
    if (! selectedFile) return
    if (! selectedFile.name.endsWith('.pdf')) {
      setError(t('filetype_expected_pdf'))
    }
    const formData = new FormData()
    formData.append('user', localStorage.getItem('token'))
    formData.append('file', selectedFile)
    try {
      const res = await fetch(`${globals.getEndpoint()}/bulk`, {
        method:'POST',
        body: formData
      })
      if (res.status === 401) {
        window.location.reload()
        return
      } else if (res.status === 200) {
        const result = await res.json()
        console.log(result)
        if (result.d100) {
          const summed = Object.values(result.d100).reduce((a, b) => a + b, 0)
          console.debug(result.d100, summed)
          setD100(summed)
        }
        if (result.is) {
          console.debug(result.is, result.cas, result.cass, result.cam, result.secondaryValues)
          setD112Is(result.is)
          setD112Cas(result.cas)
          setD112Cass(result.cass)
          setD112Cam(result.cam)
          setD112Secondaries(result.secondaryValues)
        }
        if (result.tva) {
          console.debug(result.tva)
          setTva(result.tva)
        }
      } else if (res.status === 401) {
        window.location.reload()
        return
      } else {
        console.error('Error uploading file.', res)
        setError(t(await res.text()))
      }
    } catch (error) {
      console.error('Error uploading file.', error)
      setError('Network error')
    } finally {
      setLoading(false)
    }
  }

  function handleClear() {
    setD100(null)
    setD112Is(null)
    setD112Cas(null)
    setD112Cass(null)
    setD112Cam(null)
    setD112Secondaries([])
    setTva(null)
  }

  const contents = (
    <>
      <nav>
        <button>
          <i>attach_file</i>
          <span>{t('form_file_title')}</span>
          <input type='file' accept='csv' onChange={handleUpload} />
        </button>
        <div className='max' />
        <button className='secondary' onClick={handleClear}>
          <span>{t('form_clear_data')}</span>
        </button>
      </nav>
      {error !== '' && <p className='error-text'>{error}</p>}

      <div className='small-divider' />

      <div className='grid'>
        <div className='s6 right-align'>D100</div>
        <div className='s6'>{d100 != null && `${d100} RON`}</div>

        <div className='s6 right-align'>IS</div>
        <div className='s6'>{d112Is != null && `${d112Is} RON`}</div>

        <div className='s6 right-align'>CAS</div>
        <div className='s6'>{d112Cas != null && `${d112Cas} RON`}</div>

        <div className='s6 right-align'>CASS</div>
        <div className='s6'>{d112Cass != null && `${d112Cass} RON`}</div>

        <div className='s6 right-align'>CAM</div>
        <div className='s6'>{d112Cam != null && `${d112Cam} RON`}</div>

        <div className='s6 right-align'>TVA</div>
        <div className='s6'>{tva != null && `${tva} RON`}</div>
      </div>

      <div className='small-divider' />

      <nav>
        <div className='max' />
        <button onClick={handleSendEmail}>
          {t('form_send_email')}
          <i>send</i>
        </button>
      </nav>

      {loading && <LoadingOverlay />}
    </>
  )

  return (
    <main className='responsive'>
      <img
        className='absolute bottom left large-padding'
        src={robotg}
        alt=''
        height={200}
        style={{ marginLeft: '5rem' }}
      />
      <img className='absolute bottom right large-padding l' src={calculator} alt='' height={200} />

      <article className='l m middle center absolute'>{contents}</article>

      <div className='s'>
        <div className='large-space' />
        {contents}
      </div>
    </main>
  )
}
