import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadBlob, nextMonthWithDay, usesCustomSmtp } from '../utils'
import globals from '../globals'

import LoadingOverlay from '../components/LoadingOverlay'
import { eFactura } from '../efactura'
import { InvoiceLine, Invoice, Customer } from '../api'

export default function ManageBooksPage({ userEmail, isUserPro }) {
  const fieldClasses = 'field label small'
  const { t } = useTranslation() // TODO
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [sent, setSent] = useState(false)
  const form = useRef(null)

  const now = new Date()
  const due = nextMonthWithDay(now, 25)

  const [identity, setIdentity] = useState('')
  const [issueDate, setIssueDate] = useState(now.toISOString().split('T')[0])
  const [dueDate, setDueDate] = useState(due.toISOString().split('T')[0])
  const [termsAndConditions, setTermsAndConditions] = useState('')
  const [ibanSender, setIbanRecipient] = useState(window.localStorage.getItem('invoicing_iban') || '')
  const [cc, setCC] = useState(window.localStorage.getItem('invoicing_cc') || '')

  // TODO AM: make these properties
  const [nameSender, setNameSender] = useState(window.localStorage.getItem('invoicing_name') || '')
  const [emailSender, setEmailSender] = useState(userEmail || window.localStorage.getItem('invoicing_email') || '')
  const [cuiSender, setCuiSender] = useState(window.localStorage.getItem('invoicing_cui') || '')
  const [addressSender, setAddressSender] = useState(window.localStorage.getItem('invoicing_address') || '')
  const [citySender, setCitySender] = useState(window.localStorage.getItem('invoicing_city') || '')
  const [countySender, setCountySender] = useState(window.localStorage.getItem('invoicing_county') || '')

  const [customerNamesByCui, setCustomerNamesByCui] = useState({})
  const [nameRecipient, setNameRecipient] = useState('')
  const [emailRecipient, setEmailRecipient] = useState('')
  const [cuiRecipient, setCuiRecipient] = useState('')
  const [addressRecipient, setAddressRecipient] = useState('')
  const [cityRecipient, setCityRecipient] = useState('')
  const [countyRecipient, setCountyRecipient] = useState('')

  const [foundCustomers, setFoundCustomers] = useState({})
  const [searchByName, setSearchByName] = useState(false)
  const [searchByCui, setSearchByCui] = useState(false)

  const [lines, setLines] = useState([new InvoiceLine(0)])
  const [lineId, setLineId] = useState(0)
  const [editLine, setEditLine] = useState(-1)
  const [editDescription, setEditDescription] = useState('')
  const [editDetails, setEditDetails] = useState('')
  const [editQuantity, setEditQuantity] = useState(0)
  const [editPrice, setEditPrice] = useState(0)
  const [editTax, setEditTax] = useState(0)
  const [editUnit, setEditUnit] = useState('')
  const [editSum, setEditSum] = useState(0)

  const [total, setTotal] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [totalWithoutTax, setTotalWithoutTax] = useState(0)

  useEffect(() => {
    fetch(`${globals.getEndpoint()}/customer/list?user=${window.localStorage.getItem('token')}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res=>res.ok? res.json():null)
    .then(json=>setCustomerNamesByCui(json?json:{}))
  }, [])

  useMemo(() => {
    if (searchByCui || searchByName) {
      const found = {}
      for (const [customerCui, customerName] of Object.entries(customerNamesByCui)) {
        if (
          (searchByCui &&
            cuiRecipient.length &&
            customerCui.toLocaleLowerCase().startsWith(cuiRecipient.toLocaleLowerCase())) ||
          (searchByName &&
            nameRecipient.length &&
            customerName.toLocaleLowerCase().startsWith(nameRecipient.toLocaleLowerCase()))
        ) {
          found[customerCui] = customerName
        }
        if (Object.keys(found).length >= 3) break
      }
      setFoundCustomers(found)
    }
  }, [nameRecipient, cuiRecipient, searchByCui, searchByName, customerNamesByCui])

  async function setRecipient(cui) {
    console.debug('fetching customer')
    setLoading(true)
    try {
      const res = await fetch(`${globals.getEndpoint()}/customer?user=${window.localStorage.getItem('token')}&cui=${cui}`)
      if (res.ok) {
        const recipientCustomer = await res.json()
        console.debug('Customer:', recipientCustomer)
        setNameRecipient(recipientCustomer.name)
        setEmailRecipient(recipientCustomer.email)
        setCuiRecipient(recipientCustomer.cui)
        setAddressRecipient(recipientCustomer.address)
        setCityRecipient(recipientCustomer.city)
        setCountyRecipient(recipientCustomer.county)
        if (recipientCustomer.invoiceTerms)
          setTermsAndConditions(recipientCustomer.invoiceTerms)
      }
    } catch(e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  function invoiceFromState() {
    return new Invoice(
      identity,
      issueDate,
      dueDate,
      termsAndConditions,

      nameSender,
      emailSender,
      cuiSender,
      addressSender,
      citySender,
      countySender,
      ibanSender,

      nameRecipient,
      emailRecipient,
      cuiRecipient,
      addressRecipient,
      cityRecipient,
      countyRecipient,
      lines
    )
  }

  function saveData() {
    window.localStorage.setItem('invoicing_cc', cc)
    window.localStorage.setItem('invoicing_name', nameSender)
    window.localStorage.setItem('invoicing_email', emailSender)
    window.localStorage.setItem('invoicing_cui', cuiSender)
    window.localStorage.setItem('invoicing_address', addressSender)
    window.localStorage.setItem('invoicing_city', citySender)
    window.localStorage.setItem('invoicing_county', countySender)
    window.localStorage.setItem('invoicing_iban', ibanSender)
    setCustomerNamesByCui({...customerNamesByCui, cuiRecipient: nameRecipient})
    fetch(`${globals.getEndpoint()}/customer?user=${window.localStorage.getItem('token')}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        new Customer(
          nameRecipient,
          emailRecipient,
          cuiRecipient,
          addressRecipient,
          cityRecipient,
          countyRecipient,
          termsAndConditions
        )
      ),
    })
  }

  function addLine() {
    const updateLines = [...lines, new InvoiceLine(lineId + 1)]
    setLineId(lineId + 1)
    setLines(updateLines)
  }

  function removeLine(index) {
    setEditLine(-1)
    const updateLines = [...lines]
    updateLines.splice(index, 1)
    setLines(updateLines)
  }

  function updateEditLineValues(index) {
    console.debug('Line:', index, 'Lines:', lines)
    setEditDescription(lines[index].description)
    setEditDetails(lines[index].details)
    setEditQuantity(lines[index].quantity)
    setEditPrice(lines[index].price)
    setEditTax(lines[index].tax)
    setEditUnit(lines[index].unit)

    lines[index].sum = lines[index].quantity * lines[index].price * ((100 + lines[index].tax) / 100)
    setEditSum(lines[index].sum)

    let _totalWithoutTax = 0
    let _totalWithTax = 0
    for (const line of lines) {
      const lineTotal = line.quantity * line.price
      _totalWithoutTax += lineTotal
      _totalWithTax += lineTotal * ((100 + line.tax) / 100)
    }
    setTotal(_totalWithTax)
    setTotalTax(_totalWithTax - _totalWithoutTax)
    setTotalWithoutTax(_totalWithoutTax)

    console.debug('Total with tax:', _totalWithTax, 'Without tax:', _totalWithoutTax)
  }

  function handleChangeDescription(index, e) {
    setEditLine(index)
    lines[index].description = e.target.value
    updateEditLineValues(index)
  }

  function handleChangeDetails(index, e) {
    setEditLine(index)
    lines[index].details = e.target.value
    updateEditLineValues(index)
  }

  function handleToggleDetails(index) {
    setEditLine(index)
    if (lines[index].details === null) lines[index].details = ''
    else lines[index].details = null
    updateEditLineValues(index)
  }

  function handleChangeQuantity(index, e) {
    setEditLine(index)
    lines[index].quantity = Number(e.target.value)
    updateEditLineValues(index)
  }

  function handleChangePrice(index, e) {
    setEditLine(index)
    lines[index].price = Number(e.target.value)
    updateEditLineValues(index)
  }

  function handleChangeTax(index, e) {
    setEditLine(index)
    lines[index].tax = Number(e.target.value)
    updateEditLineValues(index)
  }

  function handleChangeUnit(index, e) {
    setEditLine(index)
    lines[index].unit = e.target.value
    updateEditLineValues(index)
  }

  async function handleSendEmail() {
    setError('')
    setSent(false)
    saveData()
    if (!form.current.checkValidity()) {
      form.current.reportValidity()
      return
    }
    
    setLoading(true)
    try {
      // fetch smtp pass if the user has a custom mailserver
      const customSmtp = usesCustomSmtp()
      console.debug('Custom smtp:', customSmtp)
      let smtpPass = ''
      if (null === customSmtp || undefined === customSmtp){
        setError('Critical server error.')
        return
      }
      if (true === customSmtp) {
        smtpPass = prompt('SMTP Password:')
        if (!smtpPass) return
      }

      // send email
      const invoice = invoiceFromState()
      let url = `${globals.getEndpoint()}/invoice/email?user=${window.localStorage.getItem('token')}`
      if (smtpPass) url += `&smtpPass=${smtpPass}`
      if (cc) url += `&cc=${cc}`
      const res = await fetch(url,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(invoice),
        }
      )

      // handle errors
      if (!res.ok) {
        if (res.status === 418) {
          setError(await res.text())
        } else if (res.status === 404){
          setSent(true)
          setTimeout(() => setSent(false), 3000)
        } else {
          setError(t(await res.text()))
        }
        return
      }
      setSent(true)
      setTimeout(()=>setSent(false), 3000)
    } catch (e) {
      setError(`${e}`)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  /** Download an eFactura format XML that can be uploaded directly to ANAF */
  async function handleDownloadXml() {
    setError('')
    setSent(false)
    saveData()
    if (!form.current.checkValidity()) {
      form.current.reportValidity()
      return
    }
    const contents = eFactura(invoiceFromState())
    downloadBlob(new Blob([contents], { type: 'text/csv' }), `eF_${identity}.xml`)
  }

  /** Download the invoice as a pdf, cannot be sent to ANAF
   * Has the same data as the eFactura xml, and is sent to your client anyways.
   */
  async function handleDownloadPdf() {
    setError('')
    setSent(false)
    saveData()
    if (!form.current.checkValidity()) {
      form.current.reportValidity()
      return
    }
    setLoading(true)
    try {
      const invoice = invoiceFromState()
      const res = await fetch(`${globals.getEndpoint()}/invoice/pdf?user=${window.localStorage.getItem('token')}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(invoice),
      })
      if (!res.ok) {
        setError('TODO AM: handle errors')
        return
      }
      downloadBlob(await res.blob(), `${identity}.pdf`)
    } catch (e) {
      setError(new String(e))
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  function foundMenu() {
    return (
      <menu className='no-wrap'>
        {Object.entries(foundCustomers).map(([customerCui, customerName]) => (
          <a key={customerCui} onClick={e => setRecipient(customerCui)}>
            <span>
              <b>{customerCui}</b> {customerName}
            </span>
          </a>
        ))}
      </menu>
    )
  }


  const contextContent = (
    <div className='grid'>
      <p className='s12'>{t('invoice_all_fields_obligatory')}</p>
      <div className='s12 m4'>
        <h5>{t('invoice_title')}</h5>
        <div className={fieldClasses}>
          <input type='text' value={identity} onChange={e => setIdentity(e.target.value)} required />
          <label>{t('invoice_field_id')}</label>
          <span className='helper italic'>{t('invoice_field_id_helper')}</span>
        </div>

        <div className={fieldClasses}>
          <input type='date' value={issueDate} onChange={e => setIssueDate(e.target.value)} required />
          <label>{t('invoice_field_date_issued')}</label>
        </div>

        <div className={fieldClasses}>
          <input type='date' value={dueDate} onChange={e => setDueDate(e.target.value)} required />
          <label>{t('invoice_field_date_due')}</label>
        </div>
        <div className={fieldClasses}>
          <input type='text' value={ibanSender} onChange={e => setIbanRecipient(e.target.value)} />
          <label>IBAN</label>
          <div className='helper'>{t('invoice_field_iban_helper')}</div>
        </div>
      </div>

      <div className='s12 m4'>
        <h5>{t('invoice_title_sender')}</h5>
        <div className={fieldClasses}>
          <input type='text' value={nameSender} onChange={e => setNameSender(e.target.value)} required />
          <label>{t('invoice_field_name')}</label>
        </div>

        <div className={fieldClasses}>
          <input type='email' value={emailSender} onChange={e => setEmailSender(e.target.value)} required />
          <label>Email</label>
        </div>

        <div className={fieldClasses}>
          <input type='text' value={cuiSender} onChange={e => setCuiSender(e.target.value)} required />
          <label>{t('invoice_field_cui')}</label>
        </div>

        <div className={fieldClasses}>
          <input type='text' value={addressSender} onChange={e => setAddressSender(e.target.value)} required />
          <label>{t('Postal Address')}</label>
          <span className='helper italic'>{t('invoice_field_address_helper')}</span>
        </div>

        <div className={fieldClasses}>
          <input type='text' value={citySender} onChange={e => setCitySender(e.target.value)} required />
          <label>{t('invoice_field_city')}</label>
          <span className='helper italic'>{t('invoice_field_city_helper')}</span>
        </div>

        <div className={fieldClasses}>
          <input type='text' value={countySender} onChange={e => setCountySender(e.target.value)} required />
          <label>{t('invoice_field_county')}</label>
          <span className='helper italic'>{t('invoice_field_county_helper')}</span>
        </div>
      </div>

      <div className='s12 m4'>
        <h5>{t('Recipient')}</h5>
        <div className={fieldClasses}>
          <input
            type='text'
            value={nameRecipient}
            onChange={e => setNameRecipient(e.target.value)}
            onFocus={e => setSearchByName(true)}
            onBlur={e => setSearchByName(false)}
            required
          />
          <label>{t('invoice_field_name')}</label>
          {foundMenu()}
        </div>

        <div className={fieldClasses}>
          <input type='email' value={emailRecipient} onChange={e => setEmailRecipient(e.target.value)} required />
          <label>Email</label>
        </div>

        <div className={fieldClasses}>
          <input
            type='text'
            value={cuiRecipient}
            onChange={e => setCuiRecipient(e.target.value)}
            onFocus={e => setSearchByCui(true)}
            onBlur={e => setSearchByCui(false)}
            required
          />
          <label>{t('invoice_field_cui')}</label>
          {foundMenu()}
        </div>

        <div className={fieldClasses}>
          <input type='text' value={addressRecipient} onChange={e => setAddressRecipient(e.target.value)} required />
          <label>{t('invoice_field_address')}</label>
        </div>
        <div className={fieldClasses}>
          <input type='text' value={cityRecipient} onChange={e => setCityRecipient(e.target.value)} required />
          <label>{t('invoice_field_city')}</label>
        </div>
        <div className={fieldClasses}>
          <input type='text' value={countyRecipient} onChange={e => setCountyRecipient(e.target.value)} required />
          <label>{t('invoice_field_county')}</label>
        </div>
      </div>

      <div className='s12 left-align'>
        <h6>{t('invoice_field_terms')}</h6>
        <textarea
          className='border transparent small-padding'
          style={{ resize: 'vertical', width: '100%' }}
          value={termsAndConditions}
          onChange={e => setTermsAndConditions(e.target.value)}></textarea>
        <p>{t('invoice_field_terms_helper')}</p>
      </div>
    </div>
  )

  const linesContent = (
    <>
      <h4>{t('invoice_title_lines')}</h4>
      <div className='grid tiny-space'>
        {lines.map((line, index) => (
          <>
            <nav className='s2 m2 no-space no-padding no-margin'>
              <button
                className='circle border transparent small tiny-margin'
                onClick={() => removeLine(index)}
                type='button'>
                <i className='error-text'>remove</i>
                <div className='tooltip'>{t('invoice_line_remove_tooltip')}</div>
              </button>
              <button
                className='circle border transparent small tiny-margin'
                onClick={() => handleToggleDetails(index)}
                type='button'>
                <i>post_add</i>
                <div className='tooltip'>{t('invoice_line_details_tooltip')}</div>
              </button>
            </nav>
            <div className='s10 m4'>
              <div className={fieldClasses}>
                <input
                  type='text'
                  value={index === editLine ? editDescription : line.description}
                  onChange={e => handleChangeDescription(index, e)}
                />
                <label>{t('invoice_line_description')}</label>
              </div>
            </div>
            <div className='s2 m1'>
              <div className={fieldClasses}>
                <input
                  type='number'
                  value={index === editLine ? editQuantity : line.quantity}
                  onChange={e => handleChangeQuantity(index, e)}
                  required
                />
                <label>{t('invoice_line_quantity')}</label>
              </div>
            </div>
            <div className='s2 m1'>
              <div className={fieldClasses}>
                <input
                  type='text'
                  value={index === editLine ? editUnit : line.unit}
                  onChange={e => handleChangeUnit(index, e)}
                  required
                />
                <label>{t('invoice_line_unit')}</label>
              </div>
            </div>
            <div className='s2 m1'>
              <div className={fieldClasses}>
                <input
                  type='number'
                  value={index === editLine ? editPrice : line.price}
                  onChange={e => handleChangePrice(index, e)}
                  required
                />
                <label>{t('invoice_line_price')}</label>
              </div>
            </div>
            <div className='s3 m2'>
              <div className={fieldClasses}>
                <input
                  type='number'
                  min={0}
                  max={100}
                  value={index === editLine ? editTax : line.tax}
                  onChange={e => handleChangeTax(index, e)}
                  required
                />
                <label>{t('invoice_line_tax')}</label>
              </div>
            </div>
            <div className='s3 m1 middle-align right-align'>
              <span>{(index === editLine ? editSum : line.sum || 0).toFixed(2)} RON</span>
            </div>
            <textarea
              className={`border transparent small-padding s12 page top ${
                (index === editLine ? editDetails : line.details) !== null ? 'active' : ''
              }`}
              style={{ resize: 'vertical' }}
              onChange={e => handleChangeDetails(index, e)}>
              {index === editLine ? editDetails : line.details}
            </textarea>
            <div className='s12 s small-divider'></div>
          </>
        ))}

        <div className='m12 m l divider'></div>

        <div className='s3 m6 left-align'>
          <button className='secondary circle' onClick={addLine} type='button'>
            <i>add</i>
          </button>
        </div>

        <div className='s3 m2 middle-align right-align large-text'>
          {t('invoice_total_no_tax')} {totalWithoutTax.toFixed(2)} RON
        </div>
        <div className='s3 m2 middle-align right-align large-text'>
          {t('invoice_total_stolen_by_parasites')} {totalTax.toFixed(2)} RON
        </div>
        <div className='s3 m2 middle-align right-align large-text bold'>
          {t('invoice_total_final')} {total.toFixed(2)} RON
        </div>
      </div>
    </>
  )

  const emailConfigContent = (
    <>
      <div className='grid'>
        <h5 className='s12'>Email</h5>
        <div className={fieldClasses + ' s12 m8 l6'}>
          <input
            type='email'
            value={cc}
            onChange={e => setCC(e.target.value)}
            multiple
          />
          <label>CC</label>
          <div className='helper'>email@domain.com,other@email.com...</div>
        </div>
        <p className='s12 m4 l6 middle-align'>{t('invoice_email_cc_note')}</p>
      </div>
    </>
  )

  return (
    <main className='responsive max'>
      <div className={`snackbar primary ${sent ? 'active' : ''}`} onClick={() => setSent(false)}>
        {t('invoice_email_sent')}
      </div>
      <div className='large-space' />
      <div className='middle-align center-align'>
        <div>
          <form ref={form}>
            <article className='round'>{contextContent}</article>
            <article className='round'>{linesContent}</article>
            <article className='round left-align'>{emailConfigContent}</article>

            {error !== '' && <p className='error-text'>{error}</p>}

            <button className='large-margin' onClick={handleDownloadXml} type='button'>
              <i>upload_file</i>
              {t('form_download_efactura')}
              <i>download</i>
              <div className='tooltip bottom'>{t('invoice_note_anaf')}</div>
            </button>

            <button className='large-margin secondary' onClick={handleDownloadPdf} type='button'>
              <i>email</i>
              {t('form_download_pdf')}
              <i>download</i>
            </button>

            <button className='large-margin' onClick={handleSendEmail} type='button'>
              {t('form_send_email')}
              <i>send</i>
            </button>
          </form>

          <br />
          <div className='small-divider' />
          <a
            className='round link transparent button border margin'
            href='https://www.anaf.ro/'
            target='_blank'
            rel='noopener noreferrer'>
            ANAF<i>north_east</i>
          </a>

          {loading && <LoadingOverlay />}
        </div>
      </div>
      <div className='large-space' />
    </main>
  )
}
