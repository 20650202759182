import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'
import robotw from '../assets/robot_white.png'
import robotg from '../assets/robot_green.png'
import LoadingOverlay from '../components/LoadingOverlay'
import { treasuries } from '../treasuryIbans'
import { beerDate, nextMonthLastDue } from '../utils'
import '../components/beer_table.css'
import '../components/beer_menu.css'

const STEP_UPLOAD = 0
const STEP_PARSE = 1
const STEP_DATA = 2
const STEP_PREVIEW = 3
const STEP_SEND = 4


export default function BulkTaxes() {
  const fieldClasses = 'field label small'
  const { t } = useTranslation()

  const [step, setStep] = useState(STEP_UPLOAD)

  // Parsing
  const [parsingFiles, setParsingFiles] = useState([])
  const [parsedData, setParsedData] = useState([])

  const [parsedIndex, setParsedIndex] = useState(0)
  const [parsNames, setParseNames] = useState([])

  const [editLine, setEditLine] = useState(-1)
  const [editingSec, setEditingSec] = useState(false)
  const [editSent, setEditSent] = useState(false)
  const [editCui, setEditCui] = useState('')
  const [editYear, setEditYear] = useState(0)
  const [editMonth, setEditMonth] = useState(0)
  const [editEmail, setEditEmail] = useState('')

  const [editTrez, setEditTrez] = useState('')
  const [editSecTrez, setEditSecTrez] = useState('')
  const [searchTrez, setSearchTrez] = useState('')

  const [detailsDialog, setDetailsDialog] = useState(false)
  const [partialPayments, setPartialPayments] = useState({})
  const [editPartial, setEditPartial] = useState('')
  const [editPartialValue, setEditPartialValue] = useState(0)

  const [emailsByCui, setEmailsByCui] = useState({})
  const [foundEmails, setFoundEmails] = useState([])
  const [sendAll, setSendAll] = useState(false)
  const [dueDate, setDueDate] = useState(nextMonthLastDue().toISOString().split('T')[0])
  const [emailCC, setEmailCC] = useState('')

  // previews
  const [previewEmails, setPreviewEmails] = useState('')
  const [mailableData, setMailableData] = useState({})

  // sending
  const [sending, setSending] = useState(false)
  const [sentEmails, setSentEmails] = useState([])
  const [usesSmtp, setUsesSmtp] = useState(false)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(()=>{
    fetch(`${globals.getEndpoint()}/bulk/usesSmtp?user=${localStorage.getItem('token')}`)
      .then(res => res.ok? res.json(): new Promise(()=>false))
      .then(_usesSmtp => setUsesSmtp(_usesSmtp))
  }, []) 

  function StepButton(stepNum, stepLabel) {
    return (
      <div className='center-align'>
        <button className='circle small' disabled={step <= stepNum-1}>
          {step > stepNum ? <i>done</i> : stepNum + 1}
        </button>
        <div className='small-margin'>{t(stepLabel)}</div>
      </div>
    )
  }

  function Stepper() {
    return (
      <nav className='scroll'>
        <div className='max' />
        {StepButton(0, 'bulk_steps_upload')}
        <div className='max divider' />
        {StepButton(1, 'bulk_steps_parse')}
        <div className='max divider' />
        {StepButton(2, 'bulk_steps_select')}
        <div className='max divider' />
        {StepButton(3, 'bulk_steps_preview')}
        <div className='max divider' />
        {StepButton(4, 'bulk_steps_send')}
        <div className='max' />
      </nav>
    )
  }

  function Steps() {
    switch (step) {
      case STEP_UPLOAD:
        return StepUpload()
      case STEP_PARSE:
        return StepParse()
      case STEP_DATA:
        return StepData()
      case STEP_PREVIEW:
        return StepPreviews()
      case STEP_SEND:
        return StepSend()
      default:
        return <p className='error-text'>STEP ERROR</p>
    }
  }

  function StepUpload() {
    return (
      <article className='absolute middle center '>
        <h6>{t('form_files')}</h6>
        <button className='large-margin' id='bulk_upload'>
          <i>attach_file</i>
          <span>{t('bulk_files_upload')}</span>
          <input type='file' accept='pdf' onChange={handleUploadFiles} multiple id='bulk_upload_file'/>
        </button>
      </article>
    )
  }

  async function handleUploadFiles(event) {
    const files = Array.from(event.target.files || [])
    event.preventDefault()
    const pdfs = files.filter(f => f.name.endsWith('.pdf'))
    if (pdfs.length === 0) {
      setErrors([...errors, t('bulk_err_no_files')])
      return
    }
    console.debug('Uploaded files:', pdfs)

    // reset state
    setParsedIndex(0)
    setParseNames([])
    setErrors([])
    setEditSent(false)
    setParsingFiles(pdfs)

    setStep(STEP_PARSE) // loading step
    
    const emails = fetchEmails()
    const custs = await fetchCustomers()

    // upload all files
    const parsedFiles = {}
    const _errors = []
    for (let index = 0; index < pdfs.length; index++) {
      setParsedIndex(index)
      const pdf = pdfs[index]
      setParseNames([...Object.keys(parsedFiles), pdf.name]) // ui update
      const parsedFile = await submitFile(pdf, _errors)
      if (parsedFile) {
        parsedFile._filename = pdf.name
        parsedFile._sent = false
        parsedFile._secs = {}
        const secondaries = Object.keys(parsedFile.secondaryValues)
        for (const scui of secondaries) {
          parsedFile._secs[scui] = { city: '', iban: '' }
        }

        // type depends on what values we parsed, (we do not want to send files with value = 0)
        let type = ''
        if (parsedFile.d100 && Object.keys(parsedFile.d100).length > 0) type = 'D100'
        if (parsedFile.is || parsedFile.cas || parsedFile.cass || parsedFile.cam) type = 'D112'
        if (parsedFile.tva) type = 'D300'
        parsedFile._type = type

        // set metadata if user has already submitted this.
        if (parsedFile.contact.cui in custs) 
          parsedFile.contact = structuredClone(custs[parsedFile.contact.cui])

        parsedFiles[pdf.name] = parsedFile
      }
    }
    console.debug('Parsed files:', parsedFiles)
    setEmailsByCui(await emails)

    // sort data
    const sorted = Object.values(parsedFiles)
    sorted.sort((a, b) => a._type.localeCompare(b._type))
    sorted.sort((a, b) => a.contact.cui.localeCompare(b.contact.cui))
    console.log('Sorted files:', sorted)

    // table highlights
    for (let i = 1; i < sorted.length; i++) {
      sorted[i]._highlight = (sorted[i - 1].contact.cui != sorted[i].contact.cui)
    }
    if (sorted.length === 0) {
      setStep(STEP_UPLOAD)
      return
    }
    setParsedData(sorted)
    setEditLine(-1)
    setStep(STEP_DATA)
  }

  async function fetchCustomers() {
    const custReq = await fetch(`${globals.getEndpoint()}/customer/byCui?user=${localStorage.getItem('token')}`)
    let custs = {}
    if (custReq.ok) {
      custs = await custReq.json()
    }
    console.debug('Customers:', custs)
    return custs
  }

  function saveCustomer(customer) {
    fetch(
      `${globals.getEndpoint()}/customer?user=${localStorage.getItem('token')}`, 
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(customer),
      }
    )
  }

  async function fetchEmails() {
    const emailReq = await fetch(`${globals.getEndpoint()}/customer/emails?user=${localStorage.getItem('token')}`)
    let emailsByCui = {}
    if (emailReq.ok) {
      emailsByCui = await emailReq.json()
    }
    console.debug('Saved emails by CUI:', emailsByCui)
    return emailsByCui
  }

  async function submitFile(file, _errors) {
    try {
      const formData = new FormData()
      formData.append('user', localStorage.getItem('token'))
      formData.append('file', file)

      const res = await fetch(`${globals.getEndpoint()}/bulk`, {
        method: 'POST',
        body: formData,
      })
      if (res.status === 401) {
        window.location.reload()
      } else if (res.status === 200) {
        return await res.json()
      } else {
        const msg = `File ${file.name} is not processable.`
        console.error(msg, res)
        _errors.push(msg)
        setErrors([..._errors])
      }
    } catch (err) {
      const msg = `Unknown error: ${file.name} cannot be uploaded.`
      console.error(msg, file, err)
      _errors.push(msg)
      setErrors([..._errors])
    }
    return null
  }

  function StepParse() {
    return (
      <article className='absolute middle center'>
        <h6>{t('bulk_status_general_uploading')}</h6>
        <progress value={parsedIndex+1} max={parsingFiles.length}></progress>
        <div className='scroll medium-height'>
          {parsNames.map(filename => (
            <p key={filename}>
              <i>folder</i> {filename}
            </p>
          ))}
        </div>
      </article>
    )
  }

  function StepData() {
    return (
      <>
        <form onSubmit={handleLoadPreviews} className='no-padding no-margin' name='bulk'>
          <div className='space' />
          <h6>{t('bulk_files_input_data')}</h6>

          <div style={{ overflowX: 'scroll' }}>
            <table className='small-space'>
              <tbody>
                {parsedData.map((data, index) => {
                  return (
                    <>
                      {DataRow(data, index)}
                      {data._type == 'D112' &&
                        Object.entries(data.secondaryValues).map(([cui, sec]) => SecondaryRow(cui, data, index))}
                    </>
                  )
                })}
                <tr>
                  <td colSpan={999} style={{ height: '8em' }}>
                    <div className='large-divider'></div>
                  </td>
                </tr>
              </tbody>
              <thead className='fixed container'>
                <tr>
                  <th>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        checked={sendAll}
                        onChange={e => {
                          const all = Object.entries(parsedData)
                          console.debug(all)
                          for (let index = 0; index < all.length; index++) {
                            const [_, file] = all[index]
                            setEditLine(index)
                            file._sent = e.target.checked
                            setEditData(file, index)
                          }
                          setSendAll(e.target.checked)
                        }}
                      />
                      <span id='bulk_send_all'>
                        {t('bulk_table_header_send')}
                        <br />
                        {t('bulk_table_header_send_all')}
                      </span>
                    </label>
                  </th>
                  <th>{t('bulk_table_header_type')}</th>
                  <th>{t('bulk_table_header_company')}</th>
                  <th>{t('bulk_table_header_cui')}</th>
                  <th>{t('bulk_table_header_year')}</th>
                  <th>{t('bulk_table_header_month')}</th>
                  <th>{t('bulk_table_header_email')}</th>
                  <th>{t('bulk_table_header_city')}</th>
                  <th>
                    {t('bulk_table_header_details')}
                    <div className='tooltip'>{t('bulk_table_header_details_tooltip')}</div>
                  </th>
                  <th>{t('bulk_table_header_filename')}</th>
                </tr>
              </thead>
            </table>
          </div>

          <div className='space' />

          <nav className='small-padding'>
            <button
              type='button'
              onClick={() => {
                setStep(STEP_UPLOAD)
                setSendAll(false)
              }}
              className='secondary'
              id='bulk_from_data_back'>
              <i>arrow_back</i>
              {t('bulk_email_back_upload')}
            </button>
            <div className='max' />
            <div className='field label'>
              <input
                type='email'
                multiple
                onChange={e => setEmailCC(e.target.value)}
                value={emailCC}
                name='cc'
                id='bulk_cc'
              />
              <label>{t('bulk_email_cc')}</label>
              <div className='helper'>addr@mail.com,addr2@mail.ro...</div>
              <div className='tooltip'>{t('bulk_email_cc_hint')}</div>
            </div>

            <nav className='no-space'>
              <div className='field label'>
                <input
                  type='date'
                  onChange={e => setDueDate(e.target.value)}
                  value={dueDate}
                  name='due'
                  id='bulk_due'
                />
                <label>{t('bulk_email_due')}</label>
                <div className='helper'>mm/dd/yyyy</div>
              </div>
              <button type='button' className='border right-round' id='bulk_data_due_dynamic'>
                <span>{t('bulk_email_due_set')}</span>
                <i>arrow_drop_down</i>
                <menu id='menu_due' className='bottom-round'>
                  {[...Array(5).keys()].map(i => {
                    const now = new Date()
                    const date = nextMonthLastDue(new Date(now.getFullYear(), 6 + i, 1))
                    return (
                      <a
                        data-ui='#menu_due'
                        onClick={_ => {
                          setDueDate(beerDate(date))
                        }}>
                        {date.getDate()} / {date.getMonth() < 9 && '0'}
                        {date.getMonth() + 1} / {date.getFullYear()}
                      </a>
                    )
                  })}
                </menu>
              </button>
            </nav>

            <button type='submit' id='bulk_data_previews'>
              {t('bulk_email_preview')}
              <i>send</i>
            </button>
          </nav>
        </form>
        <div className='small-space' />
      </>
    )
  }

  function DataRow(file, index) {
    return (
      <tr
        id={`row_${file._filename.slice(0, -4)}`}
        key={file._filename}
        className={file._highlight || index === 0 ? 'surface-container' : ''}>
        <td>
          <label className='checkbox'>
            <input
              type='checkbox'
              checked={editLine == index ? editSent : file._sent}
              onChange={e => {
                setEditData(file, index)
                file._sent = e.target.checked
                setEditSent(file._sent)
              }}
              disabled={!file._type}
            />
            <span className='bulk_send'>{t('bulk_table_header_send')}</span>
          </label>
        </td>
        <td className='bulk_type'>
          {file._type || t('bulk_type_empty')}
          {!file._type && <div className='tooltip right'>{t('bulk_type_empty_explain')}</div>}
        </td>
        <td className='bulk_contact'>{file.contact.name}</td>
        <td>
          <div className={fieldClasses}>
            <input
              type='number'
              required={editLine == index ? editSent : file._sent}
              onFocus={_ => setEditData(file, index)}
              onChange={e => {
                file.contact.cui = e.target.value
                setEditCui(file.contact.cui)
              }}
              className='bulk_cui'
              value={editLine == index ? editCui : file.contact.cui}
            />
            <label>{t('bulk_table_header_cui')}</label>
          </div>
        </td>
        <td>
          <div className={fieldClasses} style={{ maxWidth: '5.5em' }}>
            <input
              type='number'
              min={2000}
              max={9999}
              required={editLine == index ? editSent : file._sent}
              onFocus={_ => setEditData(file, index)}
              onChange={e => {
                file.year = e.target.value
                setEditYear(file.year)
              }}
              value={editLine == index ? editYear : file.year}
              className='bulk_year'
            />
            <label>{t('bulk_table_header_year')}</label>
          </div>
        </td>
        <td>
          <div className={fieldClasses} style={{ maxWidth: '4em' }}>
            <input
              type='number'
              min={1}
              max={12}
              required={editLine == index ? editSent : file._sent}
              onFocus={_ => setEditData(file, index)}
              onChange={e => {
                file.month = e.target.value
                setEditMonth(file.month)
              }}
              value={editLine == index ? editMonth : file.month}
              className='bulk_month'
            />
            <label>{t('bulk_table_header_month')}</label>
          </div>
        </td>
        <td>
          <div className={fieldClasses}>
            <input
              type='email'
              required={editLine == index ? editSent : file._sent}
              onFocus={_ => setEditData(file, index)}
              onChange={e => {
                file.contact.email = e.target.value
                setEditEmail(file.contact.email)
              }}
              value={editLine == index ? editEmail : file.contact.email}
              className='bulk_email'
            />
            <label>{t('bulk_table_header_email')}</label>
            {EmailsMenu(index, file)}
          </div>
        </td>
        <td>
          <button
            type='button'
            className={'bulk_trez_menu ' + file.contact.city ? 'border' : 'transparent'}
            onClick={_ => setEditData(file, index)}>
            <span className='no-wrap'>Trez. Op. {editLine == index ? editTrez : file.contact.city}</span>
            <i>arrow_drop_down</i>
            {TrezMenuItems(file, null)}
          </button>
        </td>
        <td>
          <button className='bulk_details_menu circle transparent' 
          type='button' 
          onClick={_=>{setEditData(file, index); openDetailsDialog(file)}}
          >
            <i className='primary-text'>edit</i>
          </button>
        </td>
        <td className='filename'>{file._filename}</td>
      </tr>
    )
  }

  function SecondaryRow(cui, file, index) {
    return (
      <tr key={`sec_${file._filename}_${cui}`} id={`row_sec_${file._filename.slice(0, -4)}_${cui}`}>
        <td></td>
        <td>Sediu Secundar</td>
        <td></td>
        <td>{cui}</td>
        <td colSpan={3}></td>
        <td>
          <button
            type='button'
            className={'bulk_trez_menu ' + file._secs[cui].city ? 'border' : 'transparent'}
            onClick={_ => setEditData(file, index)}>
            <span className='no-wrap'>
              Trez. Op. {editLine == index && editingSec == cui ? editSecTrez : file._secs[cui].city}
            </span>
            <i>arrow_drop_down</i>
            {TrezMenuItems(file, cui)}
          </button>
        </td>
        <td colSpan={2}></td>
      </tr>
    )
  }

  function TrezMenuItems(file, secCui) {
    const selectedCounty = file.contact.county
    const alreadyCounty = selectedCounty && selectedCounty in treasuries
    const selectedCity = file.contact.city

    return (
      <menu className='max menu_trez'>
        <div className='field label prefix no-margin'>
          <i>search</i>
          <input
            type='text'
            value={searchTrez}
            onChange={e => setSearchTrez(e.target.value)}
            className='bulk_trez_search'
          />
          <label>{t('bulk_treasury_search')}</label>
        </div>

        {!searchTrez && alreadyCounty && (
          <>
            <a key={`treasury_recommendation_${selectedCounty}`} className='primary'>
              {selectedCounty}
            </a>
            {Object.entries(treasuries[selectedCounty]).map(([city, _]) => (
              <>
                <a
                  key={`treasury_${selectedCounty}_${city}`}
                  onClick={e => selectTrez(file, selectedCounty, city, secCui)}
                  data-ui={`.menu_trez`}
                  className={selectedCity == city ? 'active' : ''}>
                  Trez. op. {city}
                </a>
              </>
            ))}
          </>
        )}

        {Object.entries(treasuries).map(([county, cities]) => (
          <>
            <a key={`treasury_${county}`} className='secondary'>
              {county}
            </a>
            {Object.entries(cities).map(([city, _]) =>
              city.toLowerCase().includes(searchTrez.toLowerCase()) ? (
                <>
                  <a
                    className={selectedCity === city && selectedCounty === county ? 'active' : ''}
                    key={`treasury_${county}_${city}`}
                    onClick={e => selectTrez(file, county, city, secCui)}
                    data-ui={`.menu_trez`}
                    className={`bulk_trez_option_${county}_${city}`}>
                    Trez. op. {city}
                  </a>
                </>
              ) : (
                <></>
              )
            )}
          </>
        ))}
      </menu>
    )
  }

  function selectTrez(file, county, city, secCui) {
    console.debug(`Setting Treasury for ${file._filename}:${secCui?secCui:'no-secondary-cui'} to ${county}:${city}`)

    if (secCui) {
      const sec = file._secs[secCui]
      sec._county = county
      sec.city = city
      setEditSecTrez(city)
    } else {
      file.contact.county = county
      file.contact.city = city
      setEditTrez(city)
    }
  }

  function openDetailsDialog(file) {
    const partials = {
      cas: { original: file.cas, paid: getOrDefault(file.partials, 'cas', 0) },
      cass: { original: file.cass, paid: getOrDefault(file.partials, 'cass', 0) },
      cam: { original: file.cam, paid: getOrDefault(file.partials, 'cam', 0) },
      tva: { original: file.tva, paid: getOrDefault(file.partials, 'tva', 0) },
      coin: file.coin,
    }
    for (const [tag, val] of Object.entries(file.d100))
      partials[`d100 ${tag}`] = { original: val, paid: getOrDefault(file.partials, `d100 ${tag}`, 0) }
    for (const [cui, val] of Object.entries(file.secondaryValues))
      partials[`sec. ${cui}`] = { original: val, paid: getOrDefault(file.partials, `sec. ${cui}`, 0) }
    console.log(partials)
    setPartialPayments(partials)
    setDetailsDialog(true)
  }

  function getOrDefault(map, key, def) {
    if (!map) return def
    if (!map[key]) return def
    return map[key]
  }

  function BulkDetailsDialog() {
    return (
      <>
        <div className={`overlay ${detailsDialog ? 'active' : ''}`} onClick={() => setDetailsDialog(false)} />
        <dialog className={detailsDialog ? 'active' : ''} id='bulk_details_partial'>
          <h5>{t('bulk_details_title')}</h5>
          <h6>
            <i>savings</i>&nbsp;{t('bulk_details_partial_title')}
          </h6>
          <p>{t('bulk_details_partial_explain')}</p>
          <table className='small-space transparent'>
            <tbody>
              {Object.entries(partialPayments).map(([tag, values]) => {
                if (tag === 'coin' || values.original === 0) return <></>

                return (
                  <tr key={tag}>
                    <td>{tag}</td>
                    <td>
                      {values.original} {partialPayments.coin}
                    </td>
                    <td>
                      <div className={'field small'}>
                        <input
                          type='number'
                          onFocus={e => {
                            setEditPartialValue(values.paid ? values.paid : '')
                            setEditPartial(tag)
                          }}
                          onChange={e => {
                            const newValue = Math.min(e.target.value, values.original)
                            partialPayments[tag].paid = newValue
                            setEditPartialValue(newValue)
                          }}
                          className='bulk_detail_partial_payment'
                          value={editPartial === tag ? editPartialValue : values.paid}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            <thead>
              <tr>
                <th>{t('bulk_partial_code')}</th>
                <th>{t('bulk_partial_original')}</th>
                <th>{t('bulk_partial_paid')}</th>
              </tr>
            </thead>
          </table>
          <div className='small-divider' />
          <nav>
            <button type='button' className='secondary' onClick={_ => setDetailsDialog(false)}>
              {t('form_close')}
            </button>
            <div className='max' />
            <button type='button' onClick={setDetails}>
              {t('form_save')}
              <i>edit_note</i>
            </button>
          </nav>
        </dialog>
      </>
    )
  }

  function setDetails() {
    setDetailsDialog(false)
    console.debug('Setting partial payments', partialPayments)
    const file = parsedData[editLine]
    const partials = {}
    for (const [tag, partial] of Object.entries(partialPayments)) {
      if (partial.paid)
        partials[tag] = partial.paid
    }
    file.partials = partials
  }

  function setEditData(file, index, secCui) {
    setSearchTrez('')
    setEditLine(index)
    setEditCui(file.contact.cui)
    setEditYear(file.year)
    setEditMonth(file.month)
    setEditEmail(file.contact.email)
    setEditSent(file._sent)
    setEditTrez(file.contact.city)
    if (secCui) {
      setEditingSec(secCui)
      setEditSecTrez(file._secs[secCui].city)
    } else {
      setEditingSec(false)
    }
  }

  function EmailsMenu(index, file) {
    return (
      <menu className='bottom-round'>
        {foundEmails.map(email => (
          <a
            key={email}
            onClick={e => {
              e.stopPropagation()
              file.contact.email = email
              setEditEmail(email)
            }}>
            {email}
          </a>
        ))}
      </menu>
    )
  }

  useMemo(()=>{
    setFoundEmails(emailsByCui[editCui] || [])
  }, [editCui, emailsByCui])

  async function handleLoadPreviews(event) {
    event.preventDefault()
    setErrors([])
    setLoading(true)
    setMailableData({})
    console.debug('Synthing emails for preview:', parsedData)
    try {
      const aggregateData = {}
      for (const other of parsedData) {
        if (!other._sent) continue
        const key = `${other.contact.cui};${other.contact.email}`

        // Check treasuries selected AND correct value
        const city = other.contact.city
        const county = other.contact.county
        if (!county || !city || !(county in treasuries) || !(city in treasuries[county])) {
          setErrors([
            ...errors,
            t('bulk_err_no_trezo', {
              filename: other._filename,
            }),
          ])
          return
        }
        for (const [secCui, secTrezo] of Object.entries(other._secs)) {
          const county = secTrezo._county
          const city = secTrezo.city
          if (!county || !city || !(county in treasuries) || !(city in treasuries[county])) {
            setErrors([
              ...errors,
              t('bulk_err_no_trezo_sec', {
                filename: other._filename,
                secondaryCui: secCui,
              }),
            ])
            return
          }
        }

        if (!aggregateData[key]) {
          // parsed file data is not present, *copy* and add
          const copy = JSON.parse(JSON.stringify(other))
          aggregateData[key] = copy

          // Adjust partially paid values
          for (const [tag, partial] of Object.entries(copy.partials)) {
            if (tag.startsWith('d100')) {
              const subtag = tag.split(' ')[1]
              copy.d100[subtag] -= partial
              continue
            }
            if (tag.startsWith('sec.')) {
              const secCui = tag.split(' ')[1]
              copy.secondaryValues[secCui] -= partial
              continue
            }
            copy[tag] -= partial
          }
        } else { 
          // parsed file data is present and we want to add another one
          const iter = aggregateData[key]

          if (iter.contact.city !== other.contact.city) {
            setErrors([
              ...errors,
              t('bulk_err_different_trezo', {
                filenameA: iter._filename,
                filenameB: other._filename,
              }),
            ])
            return
          }

          // IS: combine taxcodes, erroring on duplicates
          if (other.d100 && Object.keys(other.d100).length > 0) {
            if (!(iter.d100 && Object.keys(iter.d100).length > 0)) {
              iter.d100 = other.d100
            } else {
              // combine
              for (const taxCode of Object.keys(other.d100)) {
                if (taxCode in iter.d100) {
                  setErrors([
                    ...errors,
                    t('bulk_err_duplicate_d100', {
                      // .split,0 - in case files are already combined with something else
                      filenameA: iter._filename.split(',')[0],
                      filenameB: other._filename.split(',')[0],
                      taxcode: taxCode,
                    }),
                  ])
                  return
                } else {
                  iter.d100[taxCode] = other.d100[taxCode]
                }
              }
            }
          }
          iter._filename += ',' + other._filename

          // cas, cass, cam, secundar
          if (other.is) iter.is = other.is
          if (other.cas) iter.cas = other.cas
          if (other.cass) iter.cass = other.cass

          if (other.cam) {
            iter.cam = other.cam
          }
          if (Object.keys(other._secs).length) {
            iter.secondaryValues = other.secondaryValues
            iter._secs = other._secs
          }

          // TVA
          if (other.tva) {
            iter.tva = other.tva
          }

          // partial payments
          if (Object.keys(other.partials).length) {
            for (const [tag, value] of Object.entries(other.partials))
            {
              iter.partials[tag] = value
            }
          }
        }
      }

      // none selected, quit
      if (Object.keys(aggregateData).length == 0) {
        setErrors([...errors, t('bulk_preview_none')])
        return
      }
      console.debug('Synthed data for previews:', aggregateData)

      // Load previews
      let previews = []
      const _errors = []
      const mailables = []
      setPreviewEmails(previews)
      setStep(STEP_PREVIEW)
      // generate mailable objects for serverside
      for (const [_, multi] of Object.entries(aggregateData)) {
        const dueSplit = dueDate.split('-')
        saveCustomer(multi.contact)

        const city = multi.contact.city
        const county = multi.contact.county
        const treas = treasuries[county][city]
        const uniqueTreas = treas['unic']
        const camTreas = treas['cam']
        const tvaTreas = treas['tva']
        for (let sec of Object.values(multi._secs)) {
          const sectreas = treasuries[sec._county][sec.city]
          sec.iban = sectreas['unic']
        }

        const mailable = {
          recipient: multi.contact.email,
          dayDue: dueSplit[2],
          monthDue: dueSplit[1],
          yearDue: dueSplit[0],
          tax: multi,
          cc: emailCC,
          treasury: { city: city, iban: uniqueTreas },
          tvaTreasury: { city: city, iban: tvaTreas },
          camTreasury: { city: city, iban: camTreas },
          secondaryTreasuries: multi._secs,
          filenames: multi._filename.split(','),
        }

        const html = await submitForPreview(mailable, _errors)
        if (!html) continue;
        const preview = {
          data: mailable,
          html: html,
        }

        previews = [... previews, preview]
        setPreviewEmails(previews)
        mailables.push(mailable)
      }
      setMailableData(mailables)
    } finally {
      setLoading(false)
    }
  }

  async function submitForPreview(mailable, _errors) {
    try {
      const res = await fetch(`${globals.getEndpoint()}/bulk/preview?user=${localStorage.getItem('token')}`, {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(mailable),
      })
      if (res.status === 401) {
        window.location.reload()
      } else if (res.status === 200) {
        return await res.text()
      } else {
        const msg = `Server error: cui ${mailable.contact.cui}, to ${mailable.recipient} cannot be rendered.`
        console.error(msg, mailable, res)
        _errors.push(msg)
        setErrors([..._errors])
      }
    } catch (err) {
      const msg = `Unknown error: cui ${mailable.contact.cui}, to ${mailable.recipient} cannot be uploaded.`
      console.error(msg, mailable, err)
      _errors.push(msg)
      setErrors([..._errors])
    }
    return null
  }

  function StepPreviews() {
    return (
      <div id='bulk_previews'>
        <h4>{t('bulk_preview_title')} </h4>
        {previewEmails.map(preview => (
          <article
            className='surface-container large-margin bulk_preview'
            key={`${preview.data.tax.contact.cui}_${preview.data.recipient}`}>
            <nav>
              <i>email</i>
              <pre className='large-text bulk_preview_recipient'>{preview.data.recipient}</pre>
            </nav>
            <p className='large-text bulk_preview_contact'>
              {t('bulk_preview_item_title', {
                company: preview.data.tax.contact.name,
                cui: preview.data.tax.contact.cui,
              })}
            </p>
            {emailCC && <p>{t('bulk_preview_title_cc', { cc: emailCC })}</p>}
            <div className='surface-container-high no-padding' dangerouslySetInnerHTML={{ __html: preview.html }} />
          </article>
        ))}
        <div className='small-space' />
        <nav>
          <div className='max' />
          <button type='button' onClick={handleBackToData} className='secondary' id='bulk_from_previews_back'>
            <i>arrow_back</i>
            {t('bulk_email_back_data')}
          </button>
          <button type='button' onClick={handleSendEmails} id='bulk_send_email'>
            {t('bulk_email_send')}
            <i>send</i>
          </button>
          <div className='max' />
        </nav>
        <div className='large-space' />
      </div>
    )
  }

  function StepSend() {
    return (
      <div className='absolute middle center'>
        <article>
          <h6>{t(sending ? 'bulk_status_general_sending' : 'bulk_status_general_sent')}</h6>
          <div className='scroll medium-height'>
            {sentEmails.map(sent => {
              ;<p>
                <i>email</i>
                cui: {sent.cui}, email: {sent.email}, cc: {sent.cc || '-'}
              </p>
            })}
          </div>
        </article>

        <nav className='max'>
          <button type='button' onClick={() => setStep(STEP_DATA)} className='secondary' id='bulk_from_send_back_data'>
            <i>arrow_back</i>
            {t('bulk_email_back_data')}
            <i>edit</i>
          </button>
          <span>{t('bulk_email_back_data_other')}</span>
          <div className='small-space' />
        </nav>
        <nav>
          <button
            type='button'
            onClick={() => {
              setStep(STEP_UPLOAD)
              setSendAll(false)
            }}
            className='tertiary'
            id='bulk_from_send_back_upload'>
            <i>arrow_back</i>
            {t('bulk_email_back_upload')}
            <i>folder</i>
          </button>
        </nav>
      </div>
    )
  }

  async function handleSendEmails() {
    setStep(STEP_SEND)
    setSending(true)

    const smtpPass = queryForSmtpPass()
    if (smtpPass === null) {
      setErrors([...errors, t('form_smtp_pass_missing')])
      return
    }

    try {
      const _errors = errors
      const _sentEmails = sentEmails
      for (const mailable of mailableData) {
        console.debug('Sending out email', mailable)
        const res = await submitEmail(smtpPass, mailable, _errors)
        if (res) {
          _sentEmails.push({ cui: mailable.tax.contact.cui, email: mailable.recipient })
          setSentEmails([..._sentEmails])
        }
      }
    } finally {
      setSending(false)
    }
  }

  function queryForSmtpPass() {
    if (!usesSmtp) return ''
    try {
      const pass = prompt(t('form_smtp_pass'))
      if (! pass) return null
      return pass
    } catch (e) {
      console.error(e)
      return ''
    }
  }

  function handleBackToData() {
    setStep(STEP_DATA)
  }

  async function submitEmail(smtpPass, mailable, _errors) {
    try {
      const res = await fetch(
        `${globals.getEndpoint()}/bulk/send?user=${localStorage.getItem('token')}&smtpPass=${smtpPass}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(mailable),
        }
      )
      if (res.status === 401) {
        window.location.reload()
      } else if (res.status === 200) {
        return await res.text()
      } else if (res.status === 406) {
        _errors.push(t('form_smtp_pass_wrong'))
        setErrors([..._errors])
      } else if (res.status === 418) {
        _errors.push(`Mailserver error: cui ${mailable.tax.contact.cui}, ${mailable.recipient}.`)
        setErrors([..._errors])
      } else {
        const msg = `Server error: cui ${mailable.tax.contact.cui}, ${mailable.recipient} .`
        console.error(msg, mailable, res)
        _errors.push(msg)
        setErrors([..._errors])
      }
    } catch (err) {
      const msg = `Unknown error: cui ${mailable.tax.contact.cui}, to ${mailable.recipient} cannot be uploaded.`
      console.error(msg, mailable, err)
      _errors.push(msg)
      setErrors([..._errors])
    }
    return null
  }

  return (
    <>
      <main className={`responsive ${step === 2 ? 'max' : ''}`} style={{ overflowY: 'hide' }}>
        <img
          className='absolute bottom left large-padding l'
          src={robotg}
          alt=''
          height={200}
          style={{ opacity: 0.8, marginLeft: '5rem' }}
        />
        <img className='absolute bottom right large-padding' src={robotw} alt='' height={200} />

        <p className='round center-align warning s m'>{t('page_not_reactive')}</p>
        <div className='small-space' />
        {Stepper()}
        {Steps()}

        <div className='large-space' />

        {loading && <LoadingOverlay />}

        {errors.map((e, i) => (
          <p>
            <div
              key={e}
              className='snackbar error active'
              style={{ marginBottom: `${i * 6}em` }}
              onClick={() => setErrors(errors.filter(_e => _e != e))}>
              {e}
            </div>
          </p>
        ))}
      </main>
      {BulkDetailsDialog()}
    </>
  )
}
