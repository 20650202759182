import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'

import robotw from '../assets/robot_white.png'
import calculator from '../assets/calculator.png'
import LoadingOverlay from '../components/LoadingOverlay'

function ManageBooksPage() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [balance, setBalance] = useState(null)
  const [dividends, setDividends] = useState(null)
  const [credit, setCredit] = useState(null)
  const [debit, setDebit] = useState(null)


  const handleSendEmail = () => {
    const formData = new FormData()
    formData.append('user', localStorage.getItem('token'))
    fetch(`${globals.getEndpoint()}/metrics/email`, { body: formData })
      .then(res => console.debug('Metric updated successfully!', res))
      .catch(err => console.debug('Error updating metric.', err))
      .finally(() => {
        const bodyContent = `Taxes Data:\nBalance: ${balance} RON\nDividends: ${dividends} RON\nCredit: ${credit} RON\nDebit: ${debit} RON`
        const mailtoLink = `mailto:me@domain.com?subject=Books&body=${encodeURIComponent(bodyContent)}`
        window.location.href = mailtoLink
      })
  }

  async function handleUpload(event) {
    const file = event.target.files[0]
    if (!file) return
    if (!file.name.endsWith('.csv')) {
      setError(t('filetype_expected_csv'))
    }

    console.debug(`File ${file.name} is uploading.`)
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('user', localStorage.getItem('token'))
      formData.append('file', file)

      const res = await fetch(`${globals.getEndpoint()}/books`, {
        method: 'POST',
        body: formData,
      })
      if (res.status === 401) {
        window.location.reload()
      } else if (res.ok) {
        const values = await res.json()
        setCredit(values['credit'])
        setDebit(values['debit'])
        setBalance(values['balance'])
        setDividends(values['dividends'])
      } else {
        console.debug(`File ${file.name} is not processable.`, res)
        setError(t('err_bad_file'))
      }
    } catch (err) {
      console.error(`File ${file.name} is errored.`, file, err)
      setError(t('err_missing_file'))
    } finally {
      setLoading(false)
    }
  }

  function handleClear() {
    setBalance(null)
    setCredit(null)
    setDebit(null)
    setDividends(null)
  }

  const contents = (
    <>
      <nav>
        <button>
          <i>attach_file</i>
          <span>{t('form_file_title')}</span>
          <input type='file' accept='csv' onChange={handleUpload} />
        </button>
        <div className='max' />
        <button className='secondary' onClick={handleClear}>
          <span>{t('form_clear_data')}</span>
        </button>
      </nav>
      {error !== '' && <p className='error-text'>{error}</p>}

      <div className='small-divider' />

      <div className='grid'>
        <div className='s6 right-align'>{t('books_balance')}</div>
        <div className='s6'>{(balance != null && `${balance} RON`) || ''}</div>

        <div className='s6 right-align'>{t('books_dividends')}</div>
        <div className='s6'>{dividends != null && `${dividends} RON`}</div>

        <div className='s6 right-align'>{t('books_credit')}</div>
        <div className='s6'>{credit != null && `${credit} RON`}</div>

        <div className='s6 right-align'>{t('books_debit')}</div>
        <div className='s6'>{debit != null && `${debit} RON`}</div>
      </div>

      <div className='small-divider' />

      <nav>
        <div className='max' />
        <button onClick={handleSendEmail}>
          {t('form_send_email')}
          <i>send</i>
        </button>
      </nav>

      {loading && <LoadingOverlay />}
    </>
  )

  return (
    <main className='responsive'>
      <img className='absolute bottom right large-padding' src={robotw} alt='white robot' height={200} />
      <img
        className='absolute bottom left large-padding l'
        src={calculator}
        alt='calculator'
        height={200}
        style={{ marginLeft: '5rem' }}
      />

      <article className='l m middle center absolute'>{contents}</article>
      <div className='s'><div className='large-space'/>{contents}</div>
    </main>
  )
}

export default ManageBooksPage
