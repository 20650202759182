import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'
import LoadingOverlay from '../components/LoadingOverlay'
import robotg from '../assets/robot_green.png'
import robotw from '../assets/robot_white.png'
import notes from '../assets/note_taking.png'

export default function SignUpAndPaymentPage() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const STEP_TIER = 0, STEP_ACCOUNT = 1, STEP_PAYMENT = 2, STEP_CONFIRM = 3
  const [step, setStep] = useState(STEP_TIER)

  const TIER_USER = 'user', TIER_PRO = 'pro'
  const [tier, setTier] = useState(TIER_USER)
  
  const PAYMENT_STRIPE = 'stripe', PAYMENT_BANK = 'bank'
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_STRIPE)
  const [email, setEmail] = useState('')

  const paymentLink = useMemo(() => {
    const links = globals.getPaymentLinks()
    let link = tier === TIER_USER ? links.user : tier === TIER_PRO ? links.mngr : null
    console.debug('Updating Payment Links:', link)
    return `${link}?prefilled_email=${email}`
  }, [tier, email])

  useEffect(() => console.debug('Step:', step), [step])

  function handleProductTierSelected(event) {
    event.preventDefault()
    setTier(new FormData(event.target).get('tier'))
    setStep(STEP_ACCOUNT)
  }

  async function handleSubmitCredentials(event) {
    event.preventDefault()
    setLoading(true)
    try {
      const data = new FormData(event.target)
      data.append('tier', tier)
      console.log(data)
      const payment = data.get('payment')
      setPaymentMethod(payment)
      setEmail(data.get('username'))
      
      const res = await fetch(`${globals.getEndpoint()}${payment === PAYMENT_BANK ? '/partial' : '/candidate'}`, {
        method: 'POST',
        body: data,
      })
      if (res.ok)
        setStep(payment === PAYMENT_BANK? STEP_CONFIRM: STEP_PAYMENT) // Bank "skips" payment
      else
        setError(t(await res.text()))
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
      const interval = setInterval(() => {
        if (step === STEP_PAYMENT) {
          console.log('Checking payment status.')
          fetch(`${globals.getEndpoint()}/candidate?username=${email}`)
          .then(res => {
            if (res.ok) setStep(STEP_CONFIRM)
          })
        }
      }, 10000)
      return () => clearInterval(interval)
  })

  function topStepperDisplay() {
    const steps = ['signup_step_product', 'signup_step_account', 'signup_step_payment', 'signup_step_confirm']
    return (
      <nav>
        {steps.map((_step, i) => (
          <>
            {i !== 0 && <div className='divider max'></div>}
            <div className='center-align' key={_step}>
              <button className='circle small' style={{cursor: 'default'}}>{step > i ? <i>done</i> : i + 1}</button>
              <div className='small-margin'>{t(_step)}</div>
            </div>
          </>
        ))}
      </nav>
    )
  }

  function stepTierDisplay() {
    return (
      <div>
        <form onSubmit={handleProductTierSelected}>
          <h2>{t('signup_tier_title')}</h2>

          <div className='grid'>
            <div className='s12 m6 l6 '>
              <article className='no-padding medium'>
                <img className='responsive absolute top' src={robotg} style={{ opacity: 0.2 }} alt=''></img>
                <div className='absolute center middle'>
                  <ul className='center-align large-text primary-text' style={{ listStyleType: '">"' }}>
                    <li>{t('signup_tier_basic_1')}</li>
                    <li>{t('signup_tier_basic_2')}</li>
                    <li>{t('signup_tier_basic_3')}</li>
                    <li>{t('signup_tier_basic_4')}</li>
                  </ul>
                  <div className='center-align'>
                    <label className='radio large-margin small-padding border round'>
                      <input type='radio' name='tier' value={TIER_USER} required id='signup_tier_basic'/>
                      <span>{t('signup_tier_basic_title')}</span>
                    </label>
                  </div>
                </div>
              </article>
            </div>

            <div className='s12 m6 l6 '>
              <article className='no-padding medium'>
                <img className='responsive absolute top' src={robotw} style={{ opacity: 0.3 }} alt=''></img>
                <div className='absolute center middle'>
                  <ul className='center-align large-text primary-text' style={{ listStyleType: '">"' }}>
                    <li>{t('signup_tier_pro_1')}</li>
                    <li>{t('signup_tier_pro_2')}</li>
                    <li>{t('signup_tier_pro_3')}</li>
                  </ul>
                  <div className='center-align'>
                    <label className='radio large-margin small-padding border round'>
                      <input type='radio' name='tier' value={TIER_PRO} required id='singnup_tier_pro'/>
                      <span>{t('signup_tier_pro_title')}</span>
                    </label>
                  </div>
                </div>
              </article>
            </div>
          </div>

          <nav>
            <div className='max' />
            <button type='submit' className='extra'>
              {t('form_continue')}
              <i>arrow_right</i>
            </button>
          </nav>
        </form>
      </div>
    )
  }

  function stepAccountDisplay() {
    return (
      <article className=''>
        <form onSubmit={handleSubmitCredentials}>
          <h2>{t('signup_form_title')}</h2>

          <div className='grid large-space'>
            <div className='s12 l6'>
              <div className={`field label prefix border ${error !== '' ? 'invalid' : ''}`}>
                <i>mail</i>
                <input type='email' name='username' required id='signup_username'/>
                <label forhtml='username'>{t('form_email')}</label>
                <span className='helper'>{t('form_email_hint')}</span>
                {error !== '' && <span className='error'>{error}</span>}
              </div>
            </div>
            <div className='s12 l6'>
              <div className='field label prefix border'>
                <i>password</i>
                <input type='password' name='password' required minLength={8} id='signup_password'/>
                <label forhtml='password'>{t('form_password')}</label>
                <span className='helper'>{t('form_password_short')}</span>
              </div>
            </div>
          </div>

          <h3>{t('signup_form_payment')}</h3>

          <label className='radio'>
            <input type='radio' name='payment' value='stripe' required id='signup_pay_stripe'/>
            <span>{t('signup_form_stripe')}</span>
          </label>
          <p>{t('signup_form_stripe_desc')}</p>

          <label className='radio'>
            <input type='radio' name='payment' value='bank' required id='signup_pay_bank'/>
            <span>{t('signup_form_bank')}</span>
          </label>
          <p>{t('signup_form_bank_desc')}</p>

          <h4>{t('form_conditions')}</h4>
          <label className='checkbox'>
            <input type='checkbox' required />
            <span id='signup_agree_terms'>{t('form_agree_terms')}</span>
          </label>
          <p>
            <a href={t('link_terms_ro')} className='link' target='_blank' rel='noopener noreferrer'>
              {t('form_terms')}
            </a>
          </p>
          <div className='small-divider' />
          <label className='checkbox'>
            <input type='checkbox' required />
            <span id='signup_agree_privacy'>{t('form_agree_privacy')}</span>
          </label>
          <p>
            <a href={t('link_privacy_ro')} className='link' target='_blank' rel='noopener noreferrer'>
              {t('form_privacy')}
            </a>
          </p>

          <nav>
            <button className='secondary circle' type='button' onClick={() => setStep(step - 1)}>
              <i>arrow_back</i>
            </button>
            <div className='max' />
            <button type='submit'>
              {t('form_continue')}
              <i>arrow_right</i>
            </button>
          </nav>
        </form>
        {loading && <LoadingOverlay />}
      </article>
    )
  }

  function stepPaymentDisplay() {
    return (
      <article>
        <h2>{t('signup_checkout_processing')}</h2>
        <h3>{email}</h3>
        <p>{t('signup_checkout_proceed')}</p>
        <p>
          <a href={paymentLink} target="_blank" rel="noopener noreferrer" className='link'>
            {t('signup_checkout_blocked')}
          </a>
        </p>
      </article>
    )
  }

  function stepConfirmDisplay() {
    return (
      <article>
        <h2>{paymentMethod === PAYMENT_BANK? t('signup_success_bank'): t('signup_success_stripe')}</h2>
        <h3>{email}</h3>
        {paymentMethod === 'bank' && <>
            <p>{t('signup_success_bank_instructions_1')}</p>
            <p>{t('signup_success_bank_instructions_2')}</p>
        </>}
      </article>
    )
  }

  return (
    <>
      <main className='responsive'>
        <img
          className='absolute bottom left m l'
          src={notes}
          height='30%'
          alt=''
          style={{ opacity: 0.8, marginLeft: '6rem' }}
        />
        <h1 className='primary-text center-align large-margin'>{t('signup_title')}</h1>
        {topStepperDisplay()}
        {step === STEP_TIER && stepTierDisplay()}
        {step === STEP_ACCOUNT && stepAccountDisplay()}
        {step === STEP_PAYMENT && stepPaymentDisplay()}
        {step === STEP_CONFIRM && stepConfirmDisplay()}
      </main>
    </>
  )
}
