export const treasuries = {
  "Bucuresti": {
    "Bucuresti": {
      "unic": "RO10TREZ7005503XXXXXXXXX",
      "cam": "RO32TREZ70020A470300XXXX",
      "tva": "RO10TREZ70020A100101XTVA"
    },
    "Sector 1": {
      "unic": "RO14TREZ7015503XXXXXXXXX",
      "cam": "RO54TREZ70120A470300XXXX",
      "tva": "RO32TREZ70120A100101XTVA"
    },
    "Sector 2": {
      "unic": "RO18TREZ7025503XXXXXXXXX",
      "cam": "RO76TREZ70220A470300XXXX",
      "tva": "RO54TREZ70220A100101XTVA"
    },
    "Sector 3": {
      "unic": "RO22TREZ7035503XXXXXXXXX",
      "cam": "RO98TREZ70320A470300XXXX",
      "tva": "RO76TREZ70320A100101XTVA"
    },
    "Sector 4": {
      "unic": "RO26TREZ7045503XXXXXXXXX",
      "cam": "RO23TREZ70420A470300XXXX",
      "tva": "RO98TREZ70420A100101XTVA"
    },
    "Sector 5": {
      "unic": "RO30TREZ7055503XXXXXXXXX",
      "cam": "RO45TREZ70520A470300XXXX",
      "tva": "RO23TREZ70520A100101XTVA"
    },
    "Sector 6": {
      "unic": "RO34TREZ7065503XXXXXXXXX",
      "cam": "RO67TREZ70620A470300XXXX",
      "tva": "RO45TREZ70620A100101XTVA"
    }
  },
  "Alba": {
    "Alba Iulia": {
      "unic": "RO31TREZ0025503XXXXXXXXX",
      "cam": "RO02TREZ00220A470300XXXX",
      "tva": "RO77TREZ00220A100101XTVA"
    },
    "Blaj": {
      "unic": "RO35TREZ0035503XXXXXXXXX",
      "cam": "RO24TREZ00320A470300XXXX",
      "tva": "RO02TREZ00320A100101XTVA"
    },
    "Aiud": {
      "unic": "RO39TREZ0045503XXXXXXXXX",
      "cam": "RO46TREZ00420A470300XXXX",
      "tva": "RO24TREZ00420A100101XTVA"
    },
    "Sebes": {
      "unic": "RO43TREZ0055503XXXXXXXXX",
      "cam": "RO68TREZ00520A470300XXXX",
      "tva": "RO46TREZ00520A100101XTVA"
    },
    "Campeni": {
      "unic": "RO47TREZ0065503XXXXXXXXX",
      "cam": "RO90TREZ00620A470300XXXX",
      "tva": "RO68TREZ00620A100101XTVA"
    },
    "Cugir": {
      "unic": "RO59TREZ0095503XXXXXXXXX",
      "cam": "RO59TREZ00920A470300XXXX",
      "tva": "RO37TREZ00920A100101XTVA"
    }
  },
  "Arad": {
    "Arad": {
      "unic": "RO10TREZ0215503XXXXXXXXX",
      "cam": "RO32TREZ02120A470300XXXX",
      "tva": "RO10TREZ02120A100101XTVA"
    },
    "Chisinau Cris": {
      "unic": "RO14TREZ0225503XXXXXXXXX",
      "cam": "RO54TREZ02220A470300XXXX",
      "tva": "RO32TREZ02220A100101XTVA"
    },
    "Ineu": {
      "unic": "RO22TREZ0245503XXXXXXXXX",
      "cam": "RO98TREZ02420A470300XXXX",
      "tva": "RO76TREZ02420A100101XTVA"
    },
    "Lipova": {
      "unic": "RO26TREZ0255503XXXXXXXXX",
      "cam": "RO23TREZ02520A470300XXXX",
      "tva": "RO98TREZ02520A100101XTVA"
    },
    "Sebis": {
      "unic": "RO38TREZ0285503XXXXXXXXX",
      "cam": "RO89TREZ02820A470300XXXX",
      "tva": "RO67TREZ02820A100101XTVA"
    },
    "Savarsin": {
      "unic": "RO54TREZ0325503XXXXXXXXX",
      "cam": "RO80TREZ03220A470300XXXX",
      "tva": "RO58TREZ03220A100101XTVA"
    }
  },
  "Arges": {
    "Pitesti": {
      "unic": "RO13TREZ0465503XXXXXXXXX",
      "cam": "RO97TREZ04620A470300XXXX",
      "tva": "RO75TREZ04620A100101XTVA"
    },
    "Campulung": {
      "unic": "RO17TREZ0475503XXXXXXXXX",
      "cam": "RO22TREZ04720A470300XXXX",
      "tva": "RO97TREZ04720A100101XTVA"
    },
    "Curtea de Arges": {
      "unic": "RO21TREZ0485503XXXXXXXXX",
      "cam": "RO44TREZ04820A470300XXXX",
      "tva": "RO22TREZ04820A100101XTVA"
    },
    "Costesti": {
      "unic": "RO29TREZ0505503XXXXXXXXX",
      "cam": "RO88TREZ05020A470300XXXX",
      "tva": "RO66TREZ05020A100101XTVA"
    },
    "Topoloveni": {
      "unic": "RO33TREZ0515503XXXXXXXXX",
      "cam": "RO13TREZ05120A470300XXXX",
      "tva": "RO88TREZ05120A100101XTVA"
    },
    "Mioveni": {
      "unic": "RO25TREZ0495503XXXXXXXXX",
      "cam": "RO66TREZ04920A470300XXXX",
      "tva": "RO44TREZ04920A100101XTVA"
    }
  },
  "Bacau": {
    "Bacau": {
      "unic": "RO73TREZ0615503XXXXXXXXX",
      "cam": "RO39TREZ06120A470300XXXX",
      "tva": "RO17TREZ06120A100101XTVA"
    },
    "Onesti": {
      "unic": "RO77TREZ0625503XXXXXXXXX",
      "cam": "RO61TREZ06220A470300XXXX",
      "tva": "RO39TREZ06220A100101XTVA"
    },
    "Moinesti": {
      "unic": "RO81TREZ0635503XXXXXXXXX",
      "cam": "RO83TREZ06320A470300XXXX",
      "tva": "RO61TREZ06320A100101XTVA"
    },
    "Buhusi": {
      "unic": "RO93TREZ0665503XXXXXXXXX",
      "cam": "RO52TREZ06620A470300XXXX",
      "tva": "RO30TREZ06620A100101XTVA"
    },
    "Comunala Podu Turcului": {
      "unic": "RO16TREZ0715503XXXXXXXXX",
      "cam": "RO65TREZ07120A470300XXXX",
      "tva": "RO43TREZ07120A100101XTVA"
    }
  },
  "Bihor": {
    "Oradea": {
      "unic": "RO36TREZ0765503XXXXXXXXX",
      "cam": "RO78TREZ07620A470300XXXX",
      "tva": "RO56TREZ07620A100101XTVA"
    },
    "Beius": {
      "unic": "RO44TREZ0785503XXXXXXXXX",
      "cam": "RO25TREZ07820A470300XXXX",
      "tva": "RO03TREZ07820A100101XTVA"
    },
    "Alesd": {
      "unic": "RO56TREZ0815503XXXXXXXXX",
      "cam": "RO91TREZ08120A470300XXXX",
      "tva": "RO69TREZ08120A100101XTVA"
    },
    "Marghita": {
      "unic": "RO60TREZ0825503XXXXXXXXX",
      "cam": "RO16TREZ08220A470300XXXX",
      "tva": "RO91TREZ08220A100101XTVA"
    },
    "Salonta": {
      "unic": "RO64TREZ0835503XXXXXXXXX",
      "cam": "RO38TREZ08320A470300XXXX",
      "tva": "RO16TREZ08320A100101XTVA"
    }
  },
  "Bistrita-Nasaud": {
    "Bistrita": {
      "unic": "RO39TREZ1015503XXXXXXXXX",
      "cam": "RO46TREZ10120A470300XXXX",
      "tva": "RO24TREZ10120A100101XTVA"
    },
    "Beclean": {
      "unic": "RO43TREZ1025503XXXXXXXXX",
      "cam": "RO68TREZ10220A470300XXXX",
      "tva": "RO46TREZ10220A100101XTVA"
    },
    "Nasaud": {
      "unic": "RO47TREZ1035503XXXXXXXXX",
      "cam": "RO90TREZ10320A470300XXXX",
      "tva": "RO68TREZ10320A100101XTVA"
    },
    "Sangeorz Bai": {
      "unic": "RO51TREZ1045503XXXXXXXXX",
      "cam": "RO15TREZ10420A470300XXXX",
      "tva": "RO90TREZ10420A100101XTVA"
    }
  },
  "Botosani": {
    "Botosani": {
      "unic": "RO02TREZ1165503XXXXXXXXX",
      "cam": "RO85TREZ11620A470300XXXX",
      "tva": "RO63TREZ11620A100101XTVA"
    },
    "Dorohoi": {
      "unic": "RO06TREZ1175503XXXXXXXXX",
      "cam": "RO10TREZ11720A470300XXXX",
      "tva": "RO85TREZ11720A100101XTVA"
    },
    "Darabani": {
      "unic": "RO10TREZ1185503XXXXXXXXX",
      "cam": "RO32TREZ11820A470300XXXX",
      "tva": "RO10TREZ11820A100101XTVA"
    },
    "Saveni": {
      "unic": "RO14TREZ1195503XXXXXXXXX",
      "cam": "RO54TREZ11920A470300XXXX",
      "tva": "RO32TREZ11920A100101XTVA"
    }
  },
  "Braila": {
    "Braila": {
      "unic": "RO45TREZ1515503XXXXXXXXX",
      "cam": "RO79TREZ15120A470300XXXX",
      "tva": "RO57TREZ15120A100101XTVA"
    },
    "Insuratei": {
      "unic": "RO49TREZ1525503XXXXXXXXX",
      "cam": "RO04TREZ15220A470300XXXX",
      "tva": "RO79TREZ15220A100101XTVA"
    },
    "Faurei": {
      "unic": "RO53TREZ1535503XXXXXXXXX",
      "cam": "RO26TREZ15320A470300XXXX",
      "tva": "RO04TREZ15320A100101XTVA"
    },
    "Ianca": {
      "unic": "RO57TREZ1545503XXXXXXXXX",
      "cam": "RO48TREZ15420A470300XXXX",
      "tva": "RO26TREZ15420A100101XTVA"
    }
  },
  "Brasov": {
    "Brasov": {
      "unic": "RO62TREZ1315503XXXXXXXXX",
      "cam": "RO27TREZ13120A470300XXXX",
      "tva": "RO05TREZ13120A100101XTVA"
    },
    "Fagaras": {
      "unic": "RO66TREZ1325503XXXXXXXXX",
      "cam": "RO49TREZ13220A470300XXXX",
      "tva": "RO27TREZ13220A100101XTVA"
    },
    "Rupea": {
      "unic": "RO70TREZ1335503XXXXXXXXX",
      "cam": "RO71TREZ13320A470300XXXX",
      "tva": "RO49TREZ13320A100101XTVA"
    },
    "Sacele": {
      "unic": "RO82TREZ1365503XXXXXXXXX",
      "cam": "RO40TREZ13620A470300XXXX",
      "tva": "RO18TREZ13620A100101XTVA"
    },
    "Codlea": {
      "unic": "RO86TREZ1375503XXXXXXXXX",
      "cam": "RO62TREZ13720A470300XXXX",
      "tva": "RO40TREZ13720A100101XTVA"
    },
    "Rasnov": {
      "unic": "RO90TREZ1385503XXXXXXXXX",
      "cam": "RO84TREZ13820A470300XXXX",
      "tva": "RO62TREZ13820A100101XTVA"
    }
  },
  "Buzau": {
    "Buzau": {
      "unic": "RO08TREZ1665503XXXXXXXXX",
      "cam": "RO21TREZ16620A470300XXXX",
      "tva": "RO96TREZ16620A100101XTVA"
    },
    "Ramnicu Sarat": {
      "unic": "RO12TREZ1675503XXXXXXXXX",
      "cam": "RO43TREZ16720A470300XXXX",
      "tva": "RO21TREZ16720A100101XTVA"
    },
    "Patarlagele": {
      "unic": "RO20TREZ1695503XXXXXXXXX",
      "cam": "RO87TREZ16920A470300XXXX",
      "tva": "RO65TREZ16920A100101XTVA"
    },
    "Pogoanele": {
      "unic": "RO24TREZ1705503XXXXXXXXX",
      "cam": "RO12TREZ17020A470300XXXX",
      "tva": "RO87TREZ17020A100101XTVA"
    }
  },
  "Calarasi": {
    "Calarasi": {
      "unic": "RO51TREZ2015503XXXXXXXXX",
      "cam": "RO15TREZ20120A470300XXXX",
      "tva": "RO90TREZ20120A100101XTVA"
    },
    "Oltenita": {
      "unic": "RO55TREZ2025503XXXXXXXXX",
      "cam": "RO37TREZ20220A470300XXXX",
      "tva": "RO15TREZ20220A100101XTVA"
    },
    "Lehliu Gara": {
      "unic": "RO59TREZ2035503XXXXXXXXX",
      "cam": "RO59TREZ20320A470300XXXX",
      "tva": "RO37TREZ20320A100101XTVA"
    },
    "Budesti": {
      "unic": "RO63TREZ2045503XXXXXXXXX",
      "cam": "RO81TREZ20420A470300XXXX",
      "tva": "RO59TREZ20420A100101XTVA"
    }
  },
  "Caras-Severin": {
    "Resita": {
      "unic": "RO68TREZ1815503XXXXXXXXX",
      "cam": "RO60TREZ18120A470300XXXX",
      "tva": "RO38TREZ18120A100101XTVA"
    },
    "Caransebes": {
      "unic": "RO72TREZ1825503XXXXXXXXX",
      "cam": "RO82TREZ18220A470300XXXX",
      "tva": "RO60TREZ18220A100101XTVA"
    },
    "Moldova Noua": {
      "unic": "RO84TREZ1855503XXXXXXXXX",
      "cam": "RO51TREZ18520A470300XXXX",
      "tva": "RO29TREZ18520A100101XTVA"
    },
    "Oravita": {
      "unic": "RO92TREZ1875503XXXXXXXXX",
      "cam": "RO95TREZ18720A470300XXXX",
      "tva": "RO73TREZ18720A100101XTVA"
    },
    "Otelul Rosu": {
      "unic": "RO96TREZ1885503XXXXXXXXX",
      "cam": "RO20TREZ18820A470300XXXX",
      "tva": "RO95TREZ18820A100101XTVA"
    },
    "Bozovici": {
      "unic": "RO07TREZ1905503XXXXXXXXX",
      "cam": "RO64TREZ19020A470300XXXX",
      "tva": "RO42TREZ19020A100101XTVA"
    }
  },
  "Cluj": {
    "Cluj-Napoca": {
      "unic": "RO14TREZ2165503XXXXXXXXX",
      "cam": "RO54TREZ21620A470300XXXX",
      "tva": "RO32TREZ21620A100101XTVA"
    },
    "Dej": {
      "unic": "RO18TREZ2175503XXXXXXXXX",
      "cam": "RO76TREZ21720A470300XXXX",
      "tva": "RO54TREZ21720A100101XTVA"
    },
    "Gherla": {
      "unic": "RO22TREZ2185503XXXXXXXXX",
      "cam": "RO98TREZ21820A470300XXXX",
      "tva": "RO76TREZ21820A100101XTVA"
    },
    "Turda": {
      "unic": "RO26TREZ2195503XXXXXXXXX",
      "cam": "RO23TREZ21920A470300XXXX",
      "tva": "RO98TREZ21920A100101XTVA"
    },
    "Huedin": {
      "unic": "RO34TREZ2215503XXXXXXXXX",
      "cam": "RO67TREZ22120A470300XXXX",
      "tva": "RO45TREZ22120A100101XTVA"
    }
  },
  "Constanta": {
    "Constanta": {
      "unic": "RO74TREZ2315503XXXXXXXXX",
      "cam": "RO93TREZ23120A470300XXXX",
      "tva": "RO71TREZ23120A100101XTVA"
    },
    "Medgidia": {
      "unic": "RO78TREZ2325503XXXXXXXXX",
      "cam": "RO18TREZ23220A470300XXXX",
      "tva": "RO93TREZ23220A100101XTVA"
    },
    "Mangalia": {
      "unic": "RO82TREZ2335503XXXXXXXXX",
      "cam": "RO40TREZ23320A470300XXXX",
      "tva": "RO18TREZ23320A100101XTVA"
    },
    "Eforie": {
      "unic": "RO94TREZ2365503XXXXXXXXX",
      "cam": "RO09TREZ23620A470300XXXX",
      "tva": "RO84TREZ23620A100101XTVA"
    },
    "Harsova": {
      "unic": "RO05TREZ2385503XXXXXXXXX",
      "cam": "RO53TREZ23820A470300XXXX",
      "tva": "RO31TREZ23820A100101XTVA"
    }
  },
  "Covasna": {
    "Sfantu Gheorghe": {
      "unic": "RO77TREZ2565503XXXXXXXXX",
      "cam": "RO61TREZ25620A470300XXXX",
      "tva": "RO39TREZ25620A100101XTVA"
    },
    "Targu Secuiesc": {
      "unic": "RO81TREZ2575503XXXXXXXXX",
      "cam": "RO83TREZ25720A470300XXXX",
      "tva": "RO61TREZ25720A100101XTVA"
    },
    "Baraolt": {
      "unic": "RO89TREZ2595503XXXXXXXXX",
      "cam": "RO30TREZ25920A470300XXXX",
      "tva": "RO08TREZ25920A100101XTVA"
    }
  },
  "Dambovita": {
    "Targoviste": {
      "unic": "RO40TREZ2715503XXXXXXXXX",
      "cam": "RO03TREZ27120A470300XXXX",
      "tva": "RO78TREZ27120A100101XTVA"
    },
    "Gaiesti": {
      "unic": "RO48TREZ2735503XXXXXXXXX",
      "cam": "RO47TREZ27320A470300XXXX",
      "tva": "RO25TREZ27320A100101XTVA"
    },
    "Moreni": {
      "unic": "RO52TREZ2745503XXXXXXXXX",
      "cam": "RO69TREZ27420A470300XXXX",
      "tva": "RO47TREZ27420A100101XTVA"
    },
    "Pucioasa": {
      "unic": "RO56TREZ2755503XXXXXXXXX",
      "cam": "RO91TREZ27520A470300XXXX",
      "tva": "RO69TREZ27520A100101XTVA"
    },
    "Titu": {
      "unic": "RO60TREZ2765503XXXXXXXXX",
      "cam": "RO16TREZ27620A470300XXXX",
      "tva": "RO91TREZ27620A100101XTVA"
    }
  },
  "Dolj": {
    "Craiova": {
      "unic": "RO23TREZ2915503XXXXXXXXX",
      "cam": "RO55TREZ29120A470300XXXX",
      "tva": "RO33TREZ29120A100101XTVA"
    },
    "Bailesti": {
      "unic": "RO27TREZ2925503XXXXXXXXX",
      "cam": "RO77TREZ29220A470300XXXX",
      "tva": "RO55TREZ29220A100101XTVA"
    },
    "Calafat": {
      "unic": "RO31TREZ2935503XXXXXXXXX",
      "cam": "RO02TREZ29320A470300XXXX",
      "tva": "RO77TREZ29320A100101XTVA"
    },
    "Filiasi": {
      "unic": "RO35TREZ2945503XXXXXXXXX",
      "cam": "RO24TREZ29420A470300XXXX",
      "tva": "RO02TREZ29420A100101XTVA"
    },
    "Segarcea": {
      "unic": "RO39TREZ2955503XXXXXXXXX",
      "cam": "RO46TREZ29520A470300XXXX",
      "tva": "RO24TREZ29520A100101XTVA"
    },
    "Bechet": {
      "unic": "RO47TREZ2975503XXXXXXXXX",
      "cam": "RO90TREZ29720A470300XXXX",
      "tva": "RO68TREZ29720A100101XTVA"
    }
  },
  "Galati": {
    "Galati": {
      "unic": "RO83TREZ3065503XXXXXXXXX",
      "cam": "RO94TREZ30620A470300XXXX",
      "tva": "RO72TREZ30620A100101XTVA"
    },
    "Tecuci": {
      "unic": "RO87TREZ3075503XXXXXXXXX",
      "cam": "RO19TREZ30720A470300XXXX",
      "tva": "RO94TREZ30720A100101XTVA"
    },
    "Targu Bujor": {
      "unic": "RO91TREZ3085503XXXXXXXXX",
      "cam": "RO41TREZ30820A470300XXXX",
      "tva": "RO19TREZ30820A100101XTVA"
    }
  },
  "Giurgiu": {
    "Giurgiu": {
      "unic": "RO46TREZ3215503XXXXXXXXX",
      "cam": "RO36TREZ32120A470300XXXX",
      "tva": "RO14TREZ32120A100101XTVA"
    },
    "Bolintin Vale": {
      "unic": "RO50TREZ3225503XXXXXXXXX",
      "cam": "RO58TREZ32220A470300XXXX",
      "tva": "RO36TREZ32220A100101XTVA"
    },
    "Mihailesti": {
      "unic": "RO54TREZ3235503XXXXXXXXX",
      "cam": "RO80TREZ32320A470300XXXX",
      "tva": "RO58TREZ32320A100101XTVA"
    }
  },
  "Gorj": {
    "Targu Jiu": {
      "unic": "RO09TREZ3365503XXXXXXXXX",
      "cam": "RO75TREZ33620A470300XXXX",
      "tva": "RO53TREZ33620A100101XTVA"
    },
    "Targu Carbunesti": {
      "unic": "RO13TREZ3375503XXXXXXXXX",
      "cam": "RO97TREZ33720A470300XXXX",
      "tva": "RO75TREZ33720A100101XTVA"
    },
    "Motru": {
      "unic": "RO17TREZ3385503XXXXXXXXX",
      "cam": "RO22TREZ33820A470300XXXX",
      "tva": "RO97TREZ33820A100101XTVA"
    },
    "Novaci": {
      "unic": "RO21TREZ3395503XXXXXXXXX",
      "cam": "RO44TREZ33920A470300XXXX",
      "tva": "RO22TREZ33920A100101XTVA"
    },
    "Rovinari": {
      "unic": "RO25TREZ3405503XXXXXXXXX",
      "cam": "RO66TREZ34020A470300XXXX",
      "tva": "RO44TREZ34020A100101XTVA"
    }
  },
  "Harghita": {
    "Miercurea Ciuc": {
      "unic": "RO69TREZ3515503XXXXXXXXX",
      "cam": "RO17TREZ35120A470300XXXX",
      "tva": "RO92TREZ35120A100101XTVA"
    },
    "Odorheiul Secuiesc": {
      "unic": "RO73TREZ3525503XXXXXXXXX",
      "cam": "RO39TREZ35220A470300XXXX",
      "tva": "RO17TREZ35220A100101XTVA"
    },
    "Gheorghieni": {
      "unic": "RO77TREZ3535503XXXXXXXXX",
      "cam": "RO61TREZ35320A470300XXXX",
      "tva": "RO39TREZ35320A100101XTVA"
    },
    "Toplita": {
      "unic": "RO81TREZ3545503XXXXXXXXX",
      "cam": "RO83TREZ35420A470300XXXX",
      "tva": "RO61TREZ35420A100101XTVA"
    }
  },
  "Hunedoara": {
    "Deva": {
      "unic": "RO32TREZ3665503XXXXXXXXX",
      "cam": "RO56TREZ36620A470300XXXX",
      "tva": "RO34TREZ36620A100101XTVA"
    },
    "Hunedoara": {
      "unic": "RO36TREZ3675503XXXXXXXXX",
      "cam": "RO78TREZ36720A470300XXXX",
      "tva": "RO56TREZ36720A100101XTVA"
    },
    "Petrosani": {
      "unic": "RO40TREZ3685503XXXXXXXXX",
      "cam": "RO03TREZ36820A470300XXXX",
      "tva": "RO78TREZ36820A100101XTVA"
    },
    "Brad": {
      "unic": "RO44TREZ3695503XXXXXXXXX",
      "cam": "RO25TREZ36920A470300XXXX",
      "tva": "RO03TREZ36920A100101XTVA"
    },
    "Orastie": {
      "unic": "RO48TREZ3705503XXXXXXXXX",
      "cam": "RO47TREZ37020A470300XXXX",
      "tva": "RO25TREZ37020A100101XTVA"
    },
    "Hateg": {
      "unic": "RO52TREZ3715503XXXXXXXXX",
      "cam": "RO69TREZ37120A470300XXXX",
      "tva": "RO47TREZ37120A100101XTVA"
    }
  },
  "Ialomita": {
    "Slobozia": {
      "unic": "RO35TREZ3915503XXXXXXXXX",
      "cam": "RO24TREZ39120A470300XXXX",
      "tva": "RO02TREZ39120A100101XTVA"
    },
    "Fetesti": {
      "unic": "RO39TREZ3925503XXXXXXXXX",
      "cam": "RO46TREZ39220A470300XXXX",
      "tva": "RO24TREZ39220A100101XTVA"
    },
    "Urziceni": {
      "unic": "RO43TREZ3935503XXXXXXXXX",
      "cam": "RO68TREZ39320A470300XXXX",
      "tva": "RO46TREZ39320A100101XTVA"
    }
  },
  "Iasi": {
    "Iasi": {
      "unic": "RO95TREZ4065503XXXXXXXXX",
      "cam": "RO63TREZ40620A470300XXXX",
      "tva": "RO41TREZ40620A100101XTVA"
    },
    "Pascani": {
      "unic": "RO02TREZ4075503XXXXXXXXX",
      "cam": "RO85TREZ40720A470300XXXX",
      "tva": "RO63TREZ40720A100101XTVA"
    },
    "Targu Frumos": {
      "unic": "RO06TREZ4085503XXXXXXXXX",
      "cam": "RO10TREZ40820A470300XXXX",
      "tva": "RO85TREZ40820A100101XTVA"
    },
    "Harlau": {
      "unic": "RO10TREZ4095503XXXXXXXXX",
      "cam": "RO32TREZ40920A470300XXXX",
      "tva": "RO10TREZ40920A100101XTVA"
    },
    "Raducaneni": {
      "unic": "RO14TREZ4105503XXXXXXXXX",
      "cam": "RO54TREZ41020A470300XXXX",
      "tva": "RO32TREZ41020A100101XTVA"
    }
  },
  "Ilfov": {
    "Ilfov": {
      "unic": "RO58TREZ4215503XXXXXXXXX",
      "cam": "RO05TREZ42120A470300XXXX",
      "tva": "RO80TREZ42120A100101XTVA"
    },
    "Buftea": {
      "unic": "RO62TREZ4225503XXXXXXXXX",
      "cam": "RO27TREZ42220A470300XXXX",
      "tva": "RO05TREZ42220A100101XTVA"
    }
  },
  "Maramures": {
    "Baia Mare": {
      "unic": "RO21TREZ4365503XXXXXXXXX",
      "cam": "RO44TREZ43620A470300XXXX",
      "tva": "RO22TREZ43620A100101XTVA"
    },
    "Sighetul Marmatiei": {
      "unic": "RO25TREZ4375503XXXXXXXXX",
      "cam": "RO66TREZ43720A470300XXXX",
      "tva": "RO44TREZ43720A100101XTVA"
    },
    "Targu Lapus": {
      "unic": "RO37TREZ4405503XXXXXXXXX",
      "cam": "RO35TREZ44020A470300XXXX",
      "tva": "RO13TREZ44020A100101XTVA"
    },
    "Viseul de Sus": {
      "unic": "RO41TREZ4415503XXXXXXXXX",
      "cam": "RO57TREZ44120A470300XXXX",
      "tva": "RO35TREZ44120A100101XTVA"
    }
  },
  "Mehedinti": {
    "Drobeta-Turnu Severin": {
      "unic": "RO24TREZ4615503XXXXXXXXX",
      "cam": "RO12TREZ46120A470300XXXX",
      "tva": "RO87TREZ46120A100101XTVA"
    },
    "Baia de Arama": {
      "unic": "RO28TREZ4625503XXXXXXXXX",
      "cam": "RO34TREZ46220A470300XXXX",
      "tva": "RO12TREZ46220A100101XTVA"
    },
    "Orsova": {
      "unic": "RO32TREZ4635503XXXXXXXXX",
      "cam": "RO56TREZ46320A470300XXXX",
      "tva": "RO34TREZ46320A100101XTVA"
    },
    "Strehaia": {
      "unic": "RO36TREZ4645503XXXXXXXXX",
      "cam": "RO78TREZ46420A470300XXXX",
      "tva": "RO56TREZ46420A100101XTVA"
    },
    "Vanju Mare": {
      "unic": "RO40TREZ4655503XXXXXXXXX",
      "cam": "RO03TREZ46520A470300XXXX",
      "tva": "RO78TREZ46520A100101XTVA"
    }
  },
  "Mures": {
    "Targu Mures": {
      "unic": "RO84TREZ4765503XXXXXXXXX",
      "cam": "RO51TREZ47620A470300XXXX",
      "tva": "RO29TREZ47620A100101XTVA"
    },
    "Reghin": {
      "unic": "RO88TREZ4775503XXXXXXXXX",
      "cam": "RO73TREZ47720A470300XXXX",
      "tva": "RO51TREZ47720A100101XTVA"
    },
    "Sighisoara": {
      "unic": "RO92TREZ4785503XXXXXXXXX",
      "cam": "RO95TREZ47820A470300XXXX",
      "tva": "RO73TREZ47820A100101XTVA"
    },
    "Tarnaveni": {
      "unic": "RO96TREZ4795503XXXXXXXXX",
      "cam": "RO20TREZ47920A470300XXXX",
      "tva": "RO95TREZ47920A100101XTVA"
    },
    "Ludus": {
      "unic": "RO03TREZ4805503XXXXXXXXX",
      "cam": "RO42TREZ48020A470300XXXX",
      "tva": "RO20TREZ48020A100101XTVA"
    },
    "Sovata": {
      "unic": "RO11TREZ4825503XXXXXXXXX",
      "cam": "RO86TREZ48220A470300XXXX",
      "tva": "RO64TREZ48220A100101XTVA"
    }
  },
  "Neamt": {
    "Piatra Neamt": {
      "unic": "RO47TREZ4915503XXXXXXXXX",
      "cam": "RO90TREZ49120A470300XXXX",
      "tva": "RO68TREZ49120A100101XTVA"
    },
    "Roman": {
      "unic": "RO51TREZ4925503XXXXXXXXX",
      "cam": "RO15TREZ49220A470300XXXX",
      "tva": "RO90TREZ49220A100101XTVA"
    },
    "Oras Targu Neamt": {
      "unic": "RO55TREZ4935503XXXXXXXXX",
      "cam": "RO37TREZ49320A470300XXXX",
      "tva": "RO15TREZ49320A100101XTVA"
    },
    "Oras Bicaz": {
      "unic": "RO59TREZ4945503XXXXXXXXX",
      "cam": "RO59TREZ49420A470300XXXX",
      "tva": "RO37TREZ49420A100101XTVA"
    }
  },
  "Olt": {
    "Municipiu Slatina": {
      "unic": "RO10TREZ5065503XXXXXXXXX",
      "cam": "RO32TREZ50620A470300XXXX",
      "tva": "RO10TREZ50620A100101XTVA"
    },
    "Caracal": {
      "unic": "RO14TREZ5075503XXXXXXXXX",
      "cam": "RO54TREZ50720A470300XXXX",
      "tva": "RO32TREZ50720A100101XTVA"
    },
    "Orasul Bals": {
      "unic": "RO18TREZ5085503XXXXXXXXX",
      "cam": "RO76TREZ50820A470300XXXX",
      "tva": "RO54TREZ50820A100101XTVA"
    },
    "Orasul Corabia": {
      "unic": "RO22TREZ5095503XXXXXXXXX",
      "cam": "RO98TREZ50920A470300XXXX",
      "tva": "RO76TREZ50920A100101XTVA"
    }
  },
  "Prahova": {
    "Ploiesti": {
      "unic": "RO70TREZ5215503XXXXXXXXX",
      "cam": "RO71TREZ52120A470300XXXX",
      "tva": "RO49TREZ52120A100101XTVA"
    },
    "Campina": {
      "unic": "RO74TREZ5225503XXXXXXXXX",
      "cam": "RO93TREZ52220A470300XXXX",
      "tva": "RO71TREZ52220A100101XTVA"
    },
    "Mizil": {
      "unic": "RO82TREZ5245503XXXXXXXXX",
      "cam": "RO40TREZ52420A470300XXXX",
      "tva": "RO18TREZ52420A100101XTVA"
    },
    "Slanic": {
      "unic": "RO90TREZ5265503XXXXXXXXX",
      "cam": "RO84TREZ52620A470300XXXX",
      "tva": "RO62TREZ52620A100101XTVA"
    },
    "Valenii de Munte": {
      "unic": "RO98TREZ5285503XXXXXXXXX",
      "cam": "RO31TREZ52820A470300XXXX",
      "tva": "RO09TREZ52820A100101XTVA"
    },
    "Busteni": {
      "unic": "RO05TREZ5295503XXXXXXXXX",
      "cam": "RO53TREZ52920A470300XXXX",
      "tva": "RO31TREZ52920A100101XTVA"
    },
    "Boldesti - Scaeni": {
      "unic": "RO45TREZ5395503XXXXXXXXX",
      "cam": "RO79TREZ53920A470300XXXX",
      "tva": "RO57TREZ53920A100101XTVA"
    }
  },
  "Salaj": {
    "Zalau": {
      "unic": "RO36TREZ5615503XXXXXXXXX",
      "cam": "RO78TREZ56120A470300XXXX",
      "tva": "RO56TREZ56120A100101XTVA"
    },
    "Cehu Silvaniei": {
      "unic": "RO40TREZ5625503XXXXXXXXX",
      "cam": "RO03TREZ56220A470300XXXX",
      "tva": "RO78TREZ56220A100101XTVA"
    },
    "Şimleul Silvaniei": {
      "unic": "RO44TREZ5635503XXXXXXXXX",
      "cam": "RO25TREZ56320A470300XXXX",
      "tva": "RO03TREZ56320A100101XTVA"
    },
    "Jibou": {
      "unic": "RO48TREZ5645503XXXXXXXXX",
      "cam": "RO47TREZ56420A470300XXXX",
      "tva": "RO25TREZ56420A100101XTVA"
    }
  },
  "Satu Mare": {
    "Satu Mare": {
      "unic": "RO73TREZ5465503XXXXXXXXX",
      "cam": "RO39TREZ54620A470300XXXX",
      "tva": "RO17TREZ54620A100101XTVA"
    },
    "Carei": {
      "unic": "RO77TREZ5475503XXXXXXXXX",
      "cam": "RO61TREZ54720A470300XXXX",
      "tva": "RO39TREZ54720A100101XTVA"
    },
    "Negresti Oas": {
      "unic": "RO81TREZ5485503XXXXXXXXX",
      "cam": "RO83TREZ54820A470300XXXX",
      "tva": "RO61TREZ54820A100101XTVA"
    },
    "Tasnad": {
      "unic": "RO85TREZ5495503XXXXXXXXX",
      "cam": "RO08TREZ54920A470300XXXX",
      "tva": "RO83TREZ54920A100101XTVA"
    }
  },
  "Sibiu": {
    "Sibiu": {
      "unic": "RO96TREZ5765503XXXXXXXXX",
      "cam": "RO20TREZ57620A470300XXXX",
      "tva": "RO95TREZ57620A100101XTVA"
    },
    "Medias": {
      "unic": "RO03TREZ5775503XXXXXXXXX",
      "cam": "RO42TREZ57720A470300XXXX",
      "tva": "RO20TREZ57720A100101XTVA"
    },
    "Agnita": {
      "unic": "RO07TREZ5785503XXXXXXXXX",
      "cam": "RO64TREZ57820A470300XXXX",
      "tva": "RO42TREZ57820A100101XTVA"
    },
    "Avrig": {
      "unic": "RO19TREZ5815503XXXXXXXXX",
      "cam": "RO33TREZ58120A470300XXXX",
      "tva": "RO11TREZ58120A100101XTVA"
    },
    "Saliste": {
      "unic": "RO35TREZ5855503XXXXXXXXX",
      "cam": "RO24TREZ58520A470300XXXX",
      "tva": "RO02TREZ58520A100101XTVA"
    }
  },
  "Suceava": {
    "Suceava": {
      "unic": "RO59TREZ5915503XXXXXXXXX",
      "cam": "RO59TREZ59120A470300XXXX",
      "tva": "RO37TREZ59120A100101XTVA"
    },
    "Campulung Moldovenesc": {
      "unic": "RO63TREZ5925503XXXXXXXXX",
      "cam": "RO81TREZ59220A470300XXXX",
      "tva": "RO59TREZ59220A100101XTVA"
    },
    "Falticeni": {
      "unic": "RO67TREZ5935503XXXXXXXXX",
      "cam": "RO06TREZ59320A470300XXXX",
      "tva": "RO81TREZ59320A100101XTVA"
    },
    "Gura Humorului": {
      "unic": "RO71TREZ5945503XXXXXXXXX",
      "cam": "RO28TREZ59420A470300XXXX",
      "tva": "RO06TREZ59420A100101XTVA"
    },
    "Radauti": {
      "unic": "RO75TREZ5955503XXXXXXXXX",
      "cam": "RO50TREZ59520A470300XXXX",
      "tva": "RO28TREZ59520A100101XTVA"
    },
    "Siret": {
      "unic": "RO79TREZ5965503XXXXXXXXX",
      "cam": "RO72TREZ59620A470300XXXX",
      "tva": "RO50TREZ59620A100101XTVA"
    },
    "Vatra Dornei": {
      "unic": "RO83TREZ5975503XXXXXXXXX",
      "cam": "RO94TREZ59720A470300XXXX",
      "tva": "RO72TREZ59720A100101XTVA"
    }
  },
  "Teleorman": {
    "Alexandria": {
      "unic": "RO22TREZ6065503XXXXXXXXX",
      "cam": "RO98TREZ60620A470300XXXX",
      "tva": "RO76TREZ60620A100101XTVA"
    },
    "Turnu Magurele": {
      "unic": "RO26TREZ6075503XXXXXXXXX",
      "cam": "RO23TREZ60720A470300XXXX",
      "tva": "RO98TREZ60720A100101XTVA"
    },
    "Rosiori de Vede": {
      "unic": "RO30TREZ6085503XXXXXXXXX",
      "cam": "RO45TREZ60820A470300XXXX",
      "tva": "RO23TREZ60820A100101XTVA"
    },
    "Videle": {
      "unic": "RO34TREZ6095503XXXXXXXXX",
      "cam": "RO67TREZ60920A470300XXXX",
      "tva": "RO45TREZ60920A100101XTVA"
    },
    "Zimnicea": {
      "unic": "RO38TREZ6105503XXXXXXXXX",
      "cam": "RO89TREZ61020A470300XXXX",
      "tva": "RO67TREZ61020A100101XTVA"
    }
  },
  "Timis": {
    "Timisoara": {
      "unic": "RO82TREZ6215503XXXXXXXXX",
      "cam": "RO40TREZ62120A470300XXXX",
      "tva": "RO18TREZ62120A100101XTVA"
    },
    "Lugoj": {
      "unic": "RO90TREZ6235503XXXXXXXXX",
      "cam": "RO84TREZ62320A470300XXXX",
      "tva": "RO62TREZ62320A100101XTVA"
    },
    "Deta": {
      "unic": "RO94TREZ6245503XXXXXXXXX",
      "cam": "RO09TREZ62420A470300XXXX",
      "tva": "RO84TREZ62420A100101XTVA"
    },
    "Jimbolia": {
      "unic": "RO98TREZ6255503XXXXXXXXX",
      "cam": "RO31TREZ62520A470300XXXX",
      "tva": "RO09TREZ62520A100101XTVA"
    },
    "Buzias": {
      "unic": "RO05TREZ6265503XXXXXXXXX",
      "cam": "RO53TREZ62620A470300XXXX",
      "tva": "RO31TREZ62620A100101XTVA"
    },
    "Sannicolau Mare": {
      "unic": "RO09TREZ6275503XXXXXXXXX",
      "cam": "RO75TREZ62720A470300XXXX",
      "tva": "RO53TREZ62720A100101XTVA"
    },
    "Faget": {
      "unic": "RO13TREZ6285503XXXXXXXXX",
      "cam": "RO97TREZ62820A470300XXXX",
      "tva": "RO75TREZ62820A100101XTVA"
    }
  },
  "Tulcea": {
    "Tulcea": {
      "unic": "RO65TREZ6415503XXXXXXXXX",
      "cam": "RO92TREZ64120A470300XXXX",
      "tva": "RO70TREZ64120A100101XTVA"
    },
    "Macin": {
      "unic": "RO69TREZ6425503XXXXXXXXX",
      "cam": "RO17TREZ64220A470300XXXX",
      "tva": "RO92TREZ64220A100101XTVA"
    },
    "Sulina": {
      "unic": "RO77TREZ6445503XXXXXXXXX",
      "cam": "RO61TREZ64420A470300XXXX",
      "tva": "RO39TREZ64420A100101XTVA"
    },
    "Babadag": {
      "unic": "RO81TREZ6455503XXXXXXXXX",
      "cam": "RO83TREZ64520A470300XXXX",
      "tva": "RO61TREZ64520A100101XTVA"
    },
    "Baia": {
      "unic": "RO85TREZ6465503XXXXXXXXX",
      "cam": "RO08TREZ64620A470300XXXX",
      "tva": "RO83TREZ64620A100101XTVA"
    }
  },
  "Valcea": {
    "Ramnicu Valcea": {
      "unic": "RO88TREZ6715503XXXXXXXXX",
      "cam": "RO73TREZ67120A470300XXXX",
      "tva": "RO51TREZ67120A100101XTVA"
    },
    "Dragasani": {
      "unic": "RO92TREZ6725503XXXXXXXXX",
      "cam": "RO95TREZ67220A470300XXXX",
      "tva": "RO73TREZ67220A100101XTVA"
    },
    "Horezu": {
      "unic": "RO03TREZ6745503XXXXXXXXX",
      "cam": "RO42TREZ67420A470300XXXX",
      "tva": "RO20TREZ67420A100101XTVA"
    },
    "Gura Lotrului": {
      "unic": "RO11TREZ6765503XXXXXXXXX",
      "cam": "RO86TREZ67620A470300XXXX",
      "tva": "RO64TREZ67620A100101XTVA"
    },
    "Balcesti": {
      "unic": "RO23TREZ6795503XXXXXXXXX",
      "cam": "RO55TREZ67920A470300XXXX",
      "tva": "RO33TREZ67920A100101XTVA"
    },
    "Babeni": {
      "unic": "RO35TREZ6825503XXXXXXXXX",
      "cam": "RO24TREZ68220A470300XXXX",
      "tva": "RO02TREZ68220A100101XTVA"
    }
  },
  "Vaslui": {
    "Vaslui": {
      "unic": "RO28TREZ6565503XXXXXXXXX",
      "cam": "RO34TREZ65620A470300XXXX",
      "tva": "RO12TREZ65620A100101XTVA"
    },
    "Barlad": {
      "unic": "RO32TREZ6575503XXXXXXXXX",
      "cam": "RO56TREZ65720A470300XXXX",
      "tva": "RO34TREZ65720A100101XTVA"
    },
    "Husi": {
      "unic": "RO36TREZ6585503XXXXXXXXX",
      "cam": "RO78TREZ65820A470300XXXX",
      "tva": "RO56TREZ65820A100101XTVA"
    },
    "Negresti": {
      "unic": "RO40TREZ6595503XXXXXXXXX",
      "cam": "RO03TREZ65920A470300XXXX",
      "tva": "RO78TREZ65920A100101XTVA"
    }
  },
  "Vrancea": {
    "Focsani": {
      "unic": "RO71TREZ6915503XXXXXXXXX",
      "cam": "RO28TREZ69120A470300XXXX",
      "tva": "RO06TREZ69120A100101XTVA"
    },
    "Adjud": {
      "unic": "RO75TREZ6925503XXXXXXXXX",
      "cam": "RO50TREZ69220A470300XXXX",
      "tva": "RO28TREZ69220A100101XTVA"
    },
    "Panciu": {
      "unic": "RO83TREZ6945503XXXXXXXXX",
      "cam": "RO94TREZ69420A470300XXXX",
      "tva": "RO72TREZ69420A100101XTVA"
    }
  }
}
