/* eslint-disable */
import globals from '../globals'
import React, { useEffect, useState } from 'react'
import ReactTimeAgo from 'react-time-ago'

export default function AdminPage() {
  const [userList, setUserList] = useState([])

  const [metricsColumns, setMetricsColumns] = useState([])
  const [metricsList, setMetricsList] = useState([])

  const [userProperties, setUserProperties] = useState([])
  const [userPropertyEmail, setUserPropertyEmail] = useState('')
  const [userPropertyKey, setUserPropertyKey] = useState('')
  const [userPropertyValue, setUserPropertyValue] = useState('')
  const [loggedInUsers, setLoggedInUsers] = useState([])

  useEffect(() => {
    const token = window.localStorage.getItem('token')
    if (!token) return

    fetch(`${globals.getEndpoint()}/admin/users?token=${token}`)
    .then(res => {
      if (!res.ok) throw new Error(`Bad response status: ${res.status}.`)
      return res.json()
    })
    .then(data => setUserList(data))
    .catch(error => {
      alert(error)
    })

    fetch(`${globals.getEndpoint()}/admin/metrics?token=${token}`)
    .then(res => {
      if (!res.ok) throw new Error(`Bad response status: ${res.status}.`)
      return res.json()
    })
    .then(data => {
      const list = []
      let columns = []
      for (const [user, metrics] of Object.entries(data)) {
        const values = [user]
        columns = ['Username', ...Object.keys(metrics)]
        for (const [label, metric] of Object.entries(metrics)) {
          if (label === 'monthly_update_since')
            values.push(
              new Date(parseInt(metric)).toISOString().split('T')[0],
            )
          else values.push(metric)
        }
        list.push(values)
      }
      console.log(list)
      setMetricsColumns(columns)
      setMetricsList(list)
    })
    .catch(err => alert(err))
  }, [])

  function handleSetUserEnabled(user, enabled) {
    console.log(`${enabled ? 'Enabling' : 'Disabling'} ${user}`)
    const formData = new FormData()
    formData.append('token', window.localStorage.getItem('token'))
    formData.append('target', user)
    fetch(`${globals.getEndpoint()}/admin/users/${enabled ? 'enable' : 'disable'}`, {
      method: 'POST',
      body: formData,
    })
    .then(res =>  alert(res.ok? 'Success': '💩 Fail; check the console.'))
  }

  function handleDeleteUser(user) {
    console.log(`Deleting ${user}`)
    const confirm = prompt(
      `Type out DELETE to confirm`,
    )
    if (confirm !== 'DELETE') {
      alert('Confirmation failed, try again.')
      return
    }

    const formData = new FormData()
    formData.append('token', window.localStorage.getItem('token'))
    formData.append('target', user)
    fetch(`${globals.getEndpoint()}/admin/users/delete`, {
      method: 'POST',
      body: formData,
    })
    .then(res =>  alert(res.ok? 'Success': '💩 Fail; check the console.'))
  }

  const handleLoadProperties = async()=>{
    const token = window.localStorage.getItem('token')
    const res = await fetch(`${globals.getEndpoint()}/admin/userProperties?token=${token}&user=${userPropertyEmail}`)
    if (res.status !== 200) {
      alert(await res.text())
      return
    }
    const props = []
    const entries = await res.json()
    for (const key in entries) {
      props.push([key, entries[key]])
    }
    setUserProperties(props)
  }

  const handleSetProperty = async()=>{
    const formData = new FormData()
    formData.append('token', localStorage.getItem('token'))
    formData.append('user', userPropertyEmail)
    formData.append('key', userPropertyKey)
    formData.append('value', userPropertyValue)
    const res = await fetch(`${globals.getEndpoint()}/admin/userProperties`, {
      method: 'POST',
      body: formData
    })
    if (res.status !== 200) {
      alert(await res.text())
    } else {
      alert('Success!', `For ${userPropertyEmail}, property ${userPropertyKey} is ${userPropertyValue}`)
    }
  }

  async function fetchLoggedInUsers() {
    const res = await fetch(`${globals.getEndpoint()}/admin/logins?token=${localStorage.getItem('token')}`, {
      method: 'GET',
      headers: {'Content-Type':'application/json'}
    })
    if (res.status === 200) {
      setLoggedInUsers(await res.json())
    }
  }

  return (
    <main className='responsive max' style={{ overflow: 'scroll' }}>
      <h1>Admin page.</h1>
      <p>
        This webpage is not reactive but basic html, refresh to fetch new values.
        <i className='orange-text'>warning</i>
      </p>

      <details>
        <summary className='primary-text'>Users</summary>
        <table className='stripes'>
          <thead>
            <tr>
              <th>Username</th>
              <th>Enabled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userList.map(userInfo => (
              <tr key={userInfo.username}>
                <td>{userInfo.username}</td>
                <td>{userInfo.enabled ? 'enabled' : 'disabled'}</td>
                <td>
                  <button onClick={() => handleSetUserEnabled(userInfo.username, !userInfo.enabled)} className='small'>
                    {userInfo.enabled ? 'disable' : 'enable'}
                  </button>
                  <button onClick={() => handleDeleteUser(userInfo.username)} className='small'>
                    delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </details>
      <hr />

      <details>
        <summary className='primary-text'>Metrics</summary>
        <p>If a user does not have a metric row, it means the user has not done anything yet.</p>
        <table className='max'>
          <thead>
            <tr>
              {metricsColumns.map(column => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {metricsList.map(metric => (
              <tr key={metric}>
                {metricsColumns.map((_, column) => (
                  <td key={metric + ':' + column}>{metric[column]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </details>
      <hr />

      <details>
        <summary className='primary-text'>Properties</summary>
        <div>
          <input
            value={userPropertyEmail}
            onChange={e => setUserPropertyEmail(e.target.value)}
            type='email'
            placeholder='User Email'></input>
          <h3>Set property</h3>
          <input
            value={userPropertyKey}
            onChange={e => setUserPropertyKey(e.target.value)}
            type='text'
            placeholder='key'></input>
          <input
            value={userPropertyValue}
            onChange={e => setUserPropertyValue(e.target.value)}
            type='text'
            placeholder='value'></input>
          <button onClick={handleSetProperty} className='small'>
            Save or overwrite property.
          </button>
          <h3>Query properties</h3>
          <button onClick={handleLoadProperties} className='small'>
            Load user properties.
          </button>
          <table>
            <thead>
              <tr>
                <th>key</th>
                <th>value</th>
              </tr>
            </thead>
            <tbody>
              {userProperties.map(prop => (
                <tr>
                  <td>{prop[0]}</td>
                  <td>{prop[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </details>
      <hr />

      <details>
        <summary className='primary-text'>Last user activity</summary>
        <div>
          <button className='transparent link small-margin' onClick={fetchLoggedInUsers}>
            Fetch last user activity
          </button>
          <p>* Only logged in users are displayed.</p>
          {loggedInUsers.map(userLoginInfo => (
            <span className='chip'>
              {userLoginInfo.username}
              <ReactTimeAgo date={new Date(userLoginInfo.lastLoggedIn)} />
            </span>
          ))}
        </div>
      </details>
    </main>
  )
}
