import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function AccountPage({ userEmail }) {
  const navigate = useNavigate()

  return (
    <main className='responsive'>
      <h1>Your account settings</h1>
      <h2>{userEmail}</h2>
      <button onClick={() => navigate('/account/password')}>
        <i>shield</i>
        Change your password
      </button>
    </main>
  )
}
