import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'
import LoadingOverlay from '../components/LoadingOverlay'

export default function PasswordChangePage() {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const [noticeMessage, setNoticeMessage] = useState('')
  const [loading, setLoading] = useState(false)

  async function submitChangePassword(event) {
    console.debug('Requesting pass change.')
    event.preventDefault()
    setNoticeMessage('')
    setErrorMessage('')
    setLoading(true)
    try {
      const data = new FormData(event.target)
      const res = await fetch(`${globals.getEndpoint()}/password/change`, {method: 'POST', body:data })
      if (res.ok)
        setNoticeMessage(t('change_password_success'))
      else
        setErrorMessage(t(await res.text()))
    } finally {
      setLoading(false)
    }
  }

  return (
    <main className='responsive'>
      <h1>{t('change_password_title')}</h1>
      <p>{t('change_password_subtitle')}</p>
      <article>
        <form onSubmit={submitChangePassword}>
          <div className='field label prefix border'>
            <i>mail</i>
            <input type='email' name='email' required id='change_password_username' />
            <label forhtml='email'>{t('form_email_hint')}</label>
          </div>

          <div className='field label prefix border'>
            <i>no_encryption</i>
            <input type='password' name='password' required id='change_password_old' />
            <label forhtml='password'>{t('form_password_old')}</label>
          </div>

          <div className='field label prefix border'>
            <i>lock</i>
            <input type='password' name='newPassword' required minLength={8} id='change_password_new' />
            <label forhtml='newPassword'>{t('form_password_new')}</label>
          </div>

          <div className='field label prefix border'>
            <i>lock</i>
            <input type='password' name='confirmNewPassword' required minLength={8} id='change_password_confirm' />
            <label forhtml='confirmNewPassword'>{t('form_password_confirm')}</label>
          </div>

          {errorMessage !== '' && <div className='error-text'>{errorMessage}</div>}
          {noticeMessage !== '' && <p>{noticeMessage}</p>}

          {loading && <LoadingOverlay />}
        </form>
      </article>
    </main>
  )
}
