import React from 'react'
import { useTranslation } from 'react-i18next'
import globals from '../globals'

export default function PaymentDuePage() {
  const { t } = useTranslation()
  const customerPortalLink = globals.getPaymentLinks().portal

  return (
    <main className='responsive'>
      <article className='error absolute middle center'>
        <h6>{t('subscription_expired_title')}</h6>
        <a href={customerPortalLink} target='_blank' rel='noopener noreferrer'>
          {t('subscription_expired_action')}
        </a>
      </article>
    </main>
  )
}
