import globals from './globals'

export function nextMonthWithDay(date, day) {
  return date.getMonth() === 11
    ? new Date(date.getFullYear() + 1, 0, day)
    : new Date(date.getFullYear(), date.getMonth() + 1, day)
}

export function downloadBlob(blob, filename) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

export async function usesCustomSmtp() {
  try {
    const res = await fetch(`${globals.getEndpoint()}/email/config?user=${window.localStorage.getItem('token')}`)
    if (!res.ok) {
      console.warn('Error fetching email config:', await res.text())
      return null
    }
    const config = await res.json()
    console.debug('User email config:', config)
    return config['useCustom']
  } catch (e) {
    console.error('Bad error fetching email config:', e)
    return null
  }
}

/**
 * Returns a date for next month, typically the 25.
 * If it falls on the weekend, advance to monday.
 * 
 * December's dates are hardcoded for now as they
 * are announced by the state. 
 * TODO: find some logic to this (romanian 
 * bureocracy has no logic).
 * 
 * If it is december 2024, returns the 20th.
 */
export function nextMonthLastDue(date) {
  if (!date) date = new Date()
  
  //Next month's 25
  date =
    date.getUTCMonth() === 11
      ? new Date(date.getFullYear() + 1, 0, 25)
      : new Date(date.getFullYear(), date.getMonth() + 1, 25)

  //advance weekends
  while (date.getDay() === 6 || date.getDay() === 0) {
    date.setDate(date.getDate() + 1)
  }

  // December's due date is on the 20th
  if (date.getUTCMonth() === 11 && date.getFullYear() === 2024) {
    date.setDate(20)
  }
  return date
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function beerDate(date) {
  const dateStr = `${date.getFullYear()}-${date.getMonth() < 9? '0':''}${date.getMonth() + 1}-${date.getDate()}`
  console.log(dateStr)
  return dateStr
}
