import React from "react";
import {Outlet} from 'react-router-dom';


function PrivateRoute({allowed, redirect}) {

  return (
    allowed ? <Outlet/> : redirect
  )


}

export default PrivateRoute




