import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import globals from '../globals'
import LoadingOverlay from '../components/LoadingOverlay'

export default function RecoverPasswordPage() {
  const { t } = useTranslation()
  const code = new URLSearchParams(useLocation().search).get('code')
  const [errorMessage, setErrorMessage] = useState()
  const [noticeMessage, setNoticeMessage] = useState()
  const [loading, setLoading] = useState(false)

  async function submitRecovery(event) {
    setLoading(true)
    setNoticeMessage('')
    try {
      const data = new FormData(event.target)
      data.append('host', window.location.host)
      const res = await fetch(`${globals.getEndpoint()}/password/recover`, {
        method: 'POST',
        body: data,
      })
      if (res.ok) setNoticeMessage(t('recovery_done'))
      else setErrorMessage(t(await res.text()))
    } finally {
      setLoading(false)
    }
  }

  async function submitResetPassword(event) {
    setLoading(true)
    setNoticeMessage('')
    try {
      const data = new FormData(event.target)
      data.append('code', code)
      const res = await fetch(`${globals.getEndpoint()}/password/reset`, {
        method: 'POST',
        body: data,
      })
      if (res.ok) setNoticeMessage(t('recovery_done'))
      else setErrorMessage(t(await res.text()))
    } finally {
      setLoading(false)
    }
  }

  const recovery = (
    <article>
      <h1>{t('recovery_title')}</h1>
      <p>{t('recovery_instructions_1')}</p>
      <p>{t('recovery_instructions_2')}</p>
      <p className='underlined'>{t('recovery_instructions_3')}</p>

      <form onSubmit={submitRecovery}>
        <div className='field label prefix border'>
          <i>mail</i>
          <input type='email' name='email' required id='recovery_request_username'/>
          <label forhtml='email'>{t('form_email')}</label>
          <span className='helper'>{t('form_email_hint')}</span>
        </div>

        <button type='submit'>
          <i>lock</i>
          {t('recovery_submit')}
        </button>
      </form>

      {errorMessage !== '' && <p className='error-text'>{errorMessage}</p>}
      {noticeMessage !== '' && <p>{noticeMessage}</p>}

      {loading && <LoadingOverlay />}
    </article>
  )

  const request = (
    <article>
      <h1>{t('reset_password_title')}</h1>
      <p>{t('reset_password_description')}</p>
      <form onSubmit={submitResetPassword}>
        <div className='field label prefix border'>
          <i>mail</i>
          <input type='email' name='email' required id='recovery_username'/>
          <label forhtml='email'>{t('form_email')}</label>
          <span className='helper'>{t('form_email_hint')}</span>
        </div>

        <div className='field label prefix border'>
          <i>password</i>
          <input type='password' name='password' required minlength='8' id='recovery_password'/>
          <label forhtml='password'>{t('********')}</label>
          <span className='helper'>{t('form_new_password')}</span>
        </div>

        <div className='field label prefix border'>
          <i>password</i>
          <input type='password' name='confirmPassword' required minlength='8' id='recovery_confirm'/>
          <label forhtml='confirmPassword'>{t('********')}</label>
          <span className='helper'>{t('form_password_confirm')}</span>
        </div>

        <button type='submit'>
          <i>lock</i>
          {t('reset_password_submit')}
        </button>
      </form>

      {errorMessage !== '' && <p className='error-text'>{errorMessage}</p>}
      {noticeMessage !== '' && <p>{noticeMessage}</p>}

      {loading && <LoadingOverlay />}
    </article>
  )

  return (
    <main className='responsive'>
      {!code && recovery}
      {code && request}
    </main>
  )
}
