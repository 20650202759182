import React, { useState, useEffect, useMemo } from 'react'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'
import globals from './globals'

import MainPage from './pages/MainPage'
import AdminPage from './pages/AdminPage'
import ManageBooksPage from './pages/ManageBooksPage'
import Invoicing from './pages/Invoicing'
import ContactPage from './pages/ContactPage'
import ManageTaxesPage from './pages/ManageTaxesPage'
import BulkTaxes from './pages/BulkTaxes'
import AccountPage from './pages/AccountPage'
import PrivateRoute from './components/PrivateRoute'
import RecoverPasswordPage from './pages/RecoverPasswordPage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import SignUpAndPaymentPage from './pages/SignUpAndPaymentPage'

import LogInDialog from './components/LogInDialog'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import PaymentDuePage from './pages/PaymentDuePage'

import 'beercss'
import './components/theme.css'

const MINUTE_MS = 60000
const MINUTES_HEARTBEAT = 10

export default function App() {
  const [isSubmittedLogIn, setIsSubmittedLogIn] = useState(false)
  const [showLogIn, setShowLogIn] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userRole, setUserRole] = useState('')
  const [isMaintenance, setIsMaintenance] = useState(false)
  const isUserPro = useMemo(() => userRole === 'pro' || userRole === 'admin' || userRole === 'tester', [userRole])

  function ensureLoggedIn() {
    const token = window.localStorage.getItem('token')

    if (token !== null && token.trim() !== '') {
      // token present, re-login

      fetch(`${globals.getEndpoint()}/login?user=${token}`)
      .then(res => {
        setIsMaintenance(false)
        if (res.status === 200) {
          console.debug('User token is valid.')
          return res.text()
        } else if (res.status === 401) {
          console.error('User is not authorized.')
        } else if (res.status === 402) {
          console.error('User is authorized but payments are NOT up to date.')
          //TODO AM: redirect to stripe payment
        } else {
          console.error(res)
        }
        return null
      })
      .then(emailAndRole => {
        if (emailAndRole == null) {
          logOut()
        } else {
          window.localStorage.setItem('token', token)
          const items = emailAndRole.split(';')
          const email = items[0]
          const role = items[1]
          if (role !== userRole || email !== userEmail) {
            console.error('Local state and token server data are different.')
            logOut()
          }
          if (!isSubmittedLogIn) {
            logIn(email, role)
          } // ELSE we are already logged in -> do not change react state
        }
      })
      .catch(error => {
        if (error.message.includes('NetworkError')) {
          console.error('Server is not available.')
          if (window.navigator.onLine) setIsMaintenance(true)
        }
      })
    } else { // no token
      // Check if maintenance mode.
      fetch(`${globals.getEndpoint()}/ping`)
      .then(r=>setIsMaintenance(false))
      .catch(e=>{if (window.navigator.onLine) setIsMaintenance(true)})
    }
  }

  function logOut() {
    window.localStorage.removeItem('token')
    setUserEmail('')
    setUserRole('')
    setIsSubmittedLogIn(false)
    console.log('Logged out.')
  }

  function logIn(email, role) {
    setIsSubmittedLogIn(true)
    setUserEmail(email)
    setUserRole(role)
    console.log('Logged in as', email ,'with role', role)
  }

  function loadDarkMode() {
    //dark mode
    if (
      window.localStorage.getItem('darkmode') === 'true' ||
      (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.body.classList.add('dark')
    }
    if (window.localStorage.getItem('darkmode', 'true') === 'false') document.body.classList.remove('dark')
  }

  useEffect(() => {
    console.log(`IQA Tax Tools v${process.env.REACT_APP_VERSION}`)
    loadDarkMode()
    ensureLoggedIn()
    const interval = setInterval(ensureLoggedIn, MINUTE_MS * MINUTES_HEARTBEAT)
    return () => clearInterval(interval)
  }, [])

  return (
    <BrowserRouter>

      <NavBar
        isSubmittedLogIn={isSubmittedLogIn}
        setIsSubmittedLogIn={setIsSubmittedLogIn}
        isUserPro={isUserPro}
        setShowLogIn={setShowLogIn}
        userRole={userRole}
        isMaintenance={isMaintenance}
      />

      <Routes>
        <Route path='/' element={<MainPage userRole={userRole} />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/recovery' element={<RecoverPasswordPage />} />
        <Route path='/signup' element={<SignUpAndPaymentPage />} />
        <Route path='/paymentDue' element={<PaymentDuePage />} />

        <Route element={<PrivateRoute allowed={isSubmittedLogIn} redirect={<Navigate to='/' />} />}>
          <Route path='/invoice' element={<Invoicing userEmail={userEmail} isUserPro={isUserPro} />} />
          <Route path='/manageBooks' element={<ManageBooksPage />} />
          <Route path='/manageTaxes' element={<ManageTaxesPage />} />
          <Route path='/account' element={<AccountPage userEmail={userEmail} />} />
          <Route path='/account/password' element={<ChangePasswordPage />} />

          <Route element={<PrivateRoute allowed={isUserPro} redirect={<Navigate to='/' />} />}>
            <Route path='/bulk' element={<BulkTaxes />} />
          </Route>

          <Route element={<PrivateRoute allowed={userRole === 'admin'} redirect={<Navigate to='/' />} />}>
            <Route path='/admin' element={<AdminPage />} />
          </Route>
        </Route>

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>

      <Footer />

      <LogInDialog
        showLogIn={showLogIn}
        setShowLogIn={setShowLogIn}
        setIsSubmittedLogIn={setIsSubmittedLogIn}
        setUserEmail={setUserEmail}
        setUserRole={setUserRole}
      />
    </BrowserRouter>
  )
}
