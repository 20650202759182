import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import infoImage from '../assets/information.jpg'
import emailImage from '../assets/email.jpg'
import lockImage from '../assets/security.jpg'
import taxesImage from '../assets/books.jpg'
import robotg from '../assets/robot_green.png'
import robotw from '../assets/robot_white.png'
import calculator from '../assets/calculator.png'
import d100Image from '../assets/d100_pic.png'
import d112Image from '../assets/d112_pic.png'
import d300Image from '../assets/d300_pic.png'
import dMailImage from '../assets/send_email.png'
import promo from '../assets/promo.mp4'

import taxesUseImage from '../assets/example_taxes.png'
import booksUseImage from '../assets/example_books.png'
import bulkUseImage from '../assets/example_bulk.png'

export default function MainPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const aboutImages = [
    [infoImage, 'home_about_card_analysis_title', 'home_about_card_analysis_body'],
    [emailImage, 'home_about_card_mail_title', 'home_about_card_mail_body'],
    [taxesImage, 'home_about_card_books_title', 'home_about_card_books_body'],
    [lockImage, 'home_about_card_privacy_title', 'home_about_card_privacy_body'],
  ]

  return (
    <main className='responsive'>
      <img
        className='fixed bottom large-padding left l'
        src={robotg}
        height={200}
        alt=''
        style={{ opacity: 0.8, marginLeft: '5rem' }}
      />
      <img className='fixed bottom large-padding right l m' src={robotw} height={200} style={{ opacity: 0.8 }} alt='' />
      <img
        className='fixed bottom small-padding center l m'
        src={calculator}
        height={150}
        alt=''
        style={{ opacity: 0.8 }}
      />

      <div style={{ height: '100vh' }}>
        <div className='center middle'>
          <div className='extra-padding'>
            <h1 className='primary-text'>{t('home_title')}</h1>
            <div className='large-margin grid l' style={{ minHeight: 150 }}>
              <div className='l1' />
              <div className='l2'>
                <img
                  className='responsive small-elevate'
                  src={d100Image}
                  style={{ transform: 'rotate(5deg)' }}
                  alt='D100 form pdf'
                />
              </div>
              <div className='l2'>
                <img
                  className='responsive small-elevate'
                  src={d112Image}
                  style={{ transform: 'rotate(10deg)' }}
                  alt='D112 form pdf'
                />
              </div>
              <div className='l2'>
                <img
                  className='responsive small-elevate'
                  src={d300Image}
                  style={{ transform: 'rotate(30deg)' }}
                  alt='D300 or TVA form pdf'
                />
              </div>
              <div className='l2'>
                <i className='middle center extra primary-text'>arrow_right</i>
                <i className='middle center extra primary-text'>arrow_right</i>
                <i className='middle center extra primary-text'>arrow_right</i>
              </div>
              <div className='l2'>
                <img className='responsive' src={dMailImage} alt='The resulting email being sent' />
              </div>
              <div className='l1' />
            </div>

            <div className='large-space' />
            <p className='center-align'>{t('home_body')}</p>
            <nav className='center-align middle-align'>
              <button className='extra margin elevate' onClick={() => navigate('/signup')}>
                <span>{t('start_free_trial')}</span>
                <i>flag</i>
              </button>
            </nav>
          </div>
        </div>
      </div>

      <h2 className='large-margin'>{t('home_usage_title')}:</h2>
      <div className='center-align middle-align small-elevate round surface-container-low large-margin'>
        <video controls style={{ width: '100%', height: '100%' }}>
          <source src={promo} type='video/mp4' />
          Video cannot be played.
        </video>
      </div>
      <div className='grid large-space'>
        <div className='m6 s12'>
          <div className='center-align'>
            <img src={booksUseImage} alt='Usage of the books tool' style={{ borderRadius: '1rem' }} />
            <h6 className='center-align'>
              <i className='primary-text round small-margin'>edit_note</i>
              {t('home_usage_books')}
            </h6>
          </div>
        </div>

        <div className='m6 s12 center-align'>
          <div className='center-align'>
            <img src={taxesUseImage} alt='Usage of the taxes analisys tool' style={{ borderRadius: '1rem' }} />
            <h6 className='center-align'>
              <i className='primary-text round small-margin'>home_storage</i>
              {t('home_usage_taxes')}
            </h6>
          </div>
        </div>

        <div className='s12'>
          <div className='center-align'>
            <img
              className='responsive'
              src={bulkUseImage}
              alt='Usage of the bulk taxes analisys'
              style={{ borderRadius: '1rem' }}
            />
          </div>
          <h6 className='center-align'>
            <i className='primary-text round small-margin '>table_view</i>
            {t('home_usage_bulk')}
          </h6>
        </div>
      </div>

      <div className='large-space' />

      <h2 className='large-margin'>{t('home_about_title')}</h2>
      <div className='grid large-margin'>
        {aboutImages.map(about => (
          <div className='s12 m6 l3'>
            <article className={'no-padding small-blur'}>
              <img className='responsive small' src={about[0]} alt='' />
              <div className='padding'>
                <h3>{t(about[1])}</h3>
                <p>{t(about[2])}</p>
              </div>
            </article>
          </div>
        ))}
      </div>
      <div style={{ height: '2rem' }} />
    </main>
  )
}
