
function eFacturaLine(index, line) {
  return `<cac:InvoiceLine>
  <cbc:ID>${index + 1}</cbc:ID>
  <cbc:InvoicedQuantity unitCode="H87">${line.quantity.toFixed(3)}</cbc:InvoicedQuantity>
  <cbc:LineExtensionAmount currencyID="RON">3836.75</cbc:LineExtensionAmount>
  <cac:Item>
    <cbc:Name>${line.description.toUpperCase()}</cbc:Name>
    <cac:ClassifiedTaxCategory>
      <cbc:ID>O</cbc:ID>
      <cac:TaxScheme>
        <cbc:ID>VAT</cbc:ID>
      </cac:TaxScheme>
    </cac:ClassifiedTaxCategory>
  </cac:Item>
  <cac:Price>
    <cbc:PriceAmount currencyID="RON">${line.price.toFixed(4)}</cbc:PriceAmount>
  </cac:Price>
</cac:InvoiceLine>`
}

function eFacturaLines(invoice) {
  return invoice.lines.map((line, index) => eFacturaLine(index, line)).join('\n')
}

export function eFactura(invoice) {
  console.debug('eFactura of:', invoice)
  let totalWithoutTax = 0
  let totalWithTax = 0
  for (const line of invoice.lines) {
    const lineTotal = line.quantity * line.price
    totalWithoutTax += lineTotal
    totalWithTax += lineTotal * ((100 + line.tax)/100)
  }
  const totalTax = totalWithTax - totalWithoutTax
  console.debug('Total:', totalWithTax, 'Without tax:', totalWithoutTax, 'Tax:', totalTax)
  const lines = eFacturaLines(invoice)
  return `<?xml version="1.0" encoding="UTF-8"?>
<Invoice xmlns:cbc="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2"
  xmlns:udt="urn:oasis:names:specification:ubl:schema:xsd:UnqualifiedDataTypes-2"
  xmlns:cac="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2"
  xmlns:ccts="urn:un:unece:uncefact:documentation:2"
  xmlns="urn:oasis:names:specification:ubl:schema:xsd:Invoice-2"
  xmlns:qdt="urn:oasis:names:specification:ubl:schema:xsd:QualifiedDataTypes-2"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <cbc:UBLVersionID>2.1</cbc:UBLVersionID>
  <cbc:CustomizationID>urn:cen.eu:en16931:2017#compliant#urn:efactura.mfinante.ro:CIUS-RO:1.0.1</cbc:CustomizationID>
  <cbc:ID>${invoice.identity}</cbc:ID>
  <cbc:IssueDate>${invoice.issueDate}</cbc:IssueDate>
  <cbc:DueDate>${invoice.dueDate}</cbc:DueDate>
  <cbc:InvoiceTypeCode>380</cbc:InvoiceTypeCode>
  <cbc:DocumentCurrencyCode>RON</cbc:DocumentCurrencyCode>
  <cbc:TaxCurrencyCode>RON</cbc:TaxCurrencyCode>
  <cac:AccountingSupplierParty>
    <cac:Party>
      <cbc:EndpointID schemeID="EM">${invoice.emailSender}</cbc:EndpointID>
      <cac:PartyIdentification>
        <cbc:ID>${invoice.cuiSender}</cbc:ID>
      </cac:PartyIdentification>
      <cac:PostalAddress>
        <cbc:StreetName>${invoice.addressSender}</cbc:StreetName>
        <cbc:CityName>${invoice.citySender}</cbc:CityName>
        <cbc:CountrySubentity>RO-${invoice.countySender}</cbc:CountrySubentity>
        <cac:Country>
          <cbc:IdentificationCode>RO</cbc:IdentificationCode>
        </cac:Country>
      </cac:PostalAddress>
      <cac:PartyLegalEntity>
        <cbc:RegistrationName>${invoice.nameSender}</cbc:RegistrationName>
        <cbc:CompanyID>${invoice.cuiSender}</cbc:CompanyID>
      </cac:PartyLegalEntity>
    </cac:Party>
  </cac:AccountingSupplierParty>
  <cac:AccountingCustomerParty>
    <cac:Party>
      <cbc:EndpointID schemeID="EM">${invoice.emailRecipient}</cbc:EndpointID>
      <cac:PartyIdentification>
        <cbc:ID>${invoice.cuiRecipient}</cbc:ID>
      </cac:PartyIdentification>
      <cac:PostalAddress>
        <cbc:StreetName>${invoice.addressRecipient}</cbc:StreetName>
        <cbc:CityName>${invoice.cityRecipient}</cbc:CityName>
        <cbc:CountrySubentity>RO-${invoice.countyRecipient}</cbc:CountrySubentity>
        <cac:Country>
          <cbc:IdentificationCode>RO</cbc:IdentificationCode>
        </cac:Country>
      </cac:PostalAddress>
      <cac:PartyLegalEntity>
        <cbc:RegistrationName>${invoice.nameRecipient}</cbc:RegistrationName>
        <cbc:CompanyID>${invoice.cuiRecipient}</cbc:CompanyID>
      </cac:PartyLegalEntity>
    </cac:Party>
  </cac:AccountingCustomerParty>
  <cac:TaxTotal>
    <cbc:TaxAmount currencyID="RON">${totalTax.toFixed(2)}</cbc:TaxAmount>
    <cac:TaxSubtotal>
      <cbc:TaxableAmount currencyID="RON">${totalWithoutTax.toFixed(2)}</cbc:TaxableAmount>
      <cbc:TaxAmount currencyID="RON">${totalTax.toFixed(2)}</cbc:TaxAmount>
      <cac:TaxCategory>
        <cbc:ID>${invoice.taxCode}</cbc:ID>
        <cbc:TaxExemptionReasonCode>${invoice.taxExemptionReasonCode}</cbc:TaxExemptionReasonCode>
        <cbc:TaxExemptionReason>${invoice.taxExemptionReason}</cbc:TaxExemptionReason>
        <cac:TaxScheme>
          <cbc:ID>VAT</cbc:ID>
        </cac:TaxScheme>
      </cac:TaxCategory>
    </cac:TaxSubtotal>
  </cac:TaxTotal>
  <cac:LegalMonetaryTotal>
    <cbc:LineExtensionAmount currencyID="RON">3836.75</cbc:LineExtensionAmount>
    <cbc:TaxExclusiveAmount currencyID="RON">${totalWithoutTax.toFixed(2)}</cbc:TaxExclusiveAmount>
    <cbc:TaxInclusiveAmount currencyID="RON">${totalWithTax.toFixed(2)}</cbc:TaxInclusiveAmount>
    <cbc:PrepaidAmount currencyID="RON">${invoice.paid? totalWithTax.toFixed(2): '0.00'}</cbc:PrepaidAmount>
    <cbc:PayableAmount currencyID="RON">${invoice.paid? '0.00': totalWithTax.toFixed(2)}</cbc:PayableAmount>
  </cac:LegalMonetaryTotal>
  ${lines}
</Invoice>`
}
